.lab-ban {
    width: 100%;
    height: 80vh;
    position: relative;
    z-index:0;
    object-fit: cover;
    height: auto;
}

@media only screen and (max-width: 1125px) and (min-width: 890px){

    .lab-ban {
      height: 70vh;
    }

    #lab-background-img {
        display: block;
        object-fit: cover;
        height: 70vh;
    }
}

@media only screen and (max-width: 890px) and (min-width: 400px){
  .lab-ban {
    height: 60vh;
  }
  #lab-background-img {
    display: block;
    object-fit: cover;
    height: 60vh;
}
}

@media (max-width: 400px) {
  .lab-ban {
    height: 60vh;
  }
  #lab-background-img {
    display: block;
    object-fit: cover;
    height: 60vh;
}
}

.lab-ban:before {
    content: '';
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #63D7B7;
    opacity: 0.85;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@media only screen and (min-width: 1125px){
    #lab-background-img {
        display: block;
        height: 80vh;
        object-fit: cover;
    }
}


#lab-background-img {
    width: 100%;
}


.lab-ban-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position:absolute;
        top:23%;
        z-index:1;
        color: rgb(255, 255, 255);
        font-size: larger;
        /* text-align: center; */
}


.lab-text-container {
  margin-top: 2%;
  width: 75vw;
  margin-left: 14vw;
  display: inline-block;
  text-align: left;
}  


@media (max-width: 1200px) {
    .lab-text-container {
        /* margin-top: -7%; */
    }
}


/* @media (max-height: 719px) {
    .lab-text-container {
        padding-top: -3vw;
    }
} */

#lab-heading-1{
    font-size: 4vw;
    width: 0;
    animation: typing 1s steps(23, end) forwards;
    margin-bottom: 0%
}


@keyframes typing {
    from { width: 100% }
    to { width: 100% }
  }


#lab-heading-2{
    font-size: 1.5vw;
    opacity: 0;
    animation: text-visibilty 1s 1s forwards;
    padding-top: 4px;
}

#lab-heading-3{
    font-size: 1.4vw;
    font-weight: 300;
    opacity: 0;
    animation: text-visibilty 1s 1s forwards;
    padding-top: 4px;
}

@keyframes text-visibilty {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media screen and (max-width: 1430px) and (min-width:1100px){
  
    #lab-heading-1{
      font-size: 4.6vw;
  }
    
  #lab-heading-2{
    font-size: 1.7vw;
  }
  
  #lab-heading-3{
    font-size: 1.6vw;
  }

  .lab-ban-overlay  {
    top:23%;
    /* left:12%; */
  }
  
    }

@media screen and (max-width: 1100px) and (min-width:900px){

    #lab-heading-1{
        font-size: 5.2vw;
    }
    
    #lab-heading-2{
    font-size: 1.9vw;
    }

    #lab-heading-3{
        font-size: 1.8vw;
        }
    
    .lab-ban-overlay {
    top:36%;
    }

    .lab-text-container {
        margin-top: -3%;
    }
    .lab-ban {
        height: 80vh;
      }
    
      #lab-background-img {
        height: 80vh;
        }
    }


@media screen and (max-width: 900px) and (min-width:700px){
  
    #lab-heading-1{
        font-size: 6.1vw;
    }
    
    #lab-heading-2{
    font-size: 2.4vw;
    }

    #lab-heading-3{
        font-size: 2.3vw;
        }
    }


@media screen and (max-width: 700px) and (min-width:600px){

    #lab-heading-1{
        font-size: 6.6vw;
    }
    
    #lab-heading-2{
    font-size: 2.6vw;
    }

    #lab-heading-3{
        font-size: 2.5vw;
        }
    
    .lab-text-container {
    width: 70%;
    display: inline-block;
    }  

    .lab-ban-overlay {
        top:25%;
        left: -10%;
        }
    
    }
          
@media screen and (max-width: 600px) and (min-width:500px){

    #lab-heading-1{
    font-size: 7.6vw;
}
    
#lab-heading-2{
    font-size: 3vw;
}

#lab-heading-3{
    font-size: 2.9vw;
}

.lab-ban-overlay {
    top:30%;
    left: -10%;
    }

.lab-text-container {
    width: 75%;
    display: inline-block;
    margin-top: -3%;
    }  

.lab-ban {
    height: 80vh;
    }

    #lab-background-img {
    height: 80vh;
}

    }
          
@media screen and (max-width: 500px) and (min-width:400px){

#lab-heading-1{
    font-size: 8.3vw;
}

#lab-heading-2{
font-size: 3.5vw;
}

#lab-heading-3{
    font-size: 3.4vw;
    }

.lab-ban-overlay {
    top:27%;
    left: -11.5%;
    }

.lab-text-container {
    width: 75%;
    display: inline-block;
}  

.lab-ban {
    height: 75vh;
    }

    #lab-background-img {
    height: 75vh;
}

}
          
@media screen and (max-width: 400px) and (min-width:200px){

    #lab-heading-1{
    font-size: 10vw;
}
    
#lab-heading-2{
    font-size: 4vw;
}

#lab-heading-3{
    font-size: 3.9vw;
}

.lab-ban-overlay {
    top:24%;
    left: -11.5%;
    }

.lab-text-container {
    
    width: 75%;
    display: inline-block;
    }  

.lab-text-container {
    margin-top: -2%;
}
.lab-ban {
    height: 80vh;
    }

    #lab-background-img {
    height: 80vh;
}
}