@font-face {
    font-family: "Inter";
    /* src: url("./fonts/Inter-3.19/Inter Desktop/Inter-V.ttf") format("truetype"); */
    src: url("../fonts/Inter-3.19/Inter Desktop/Inter-V.ttf") format("truetype");

  }
  
  @font-face {
    font-family: "Public Sans";
    src: url("../fonts/public-sans-v2.001/fonts/ttf/PublicSans-Regular.ttf") format("truetype");
  }