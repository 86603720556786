#res-contact-ban {
    padding-top: 0.2%;
    margin-top: 7%;
    padding-bottom: 5%;
    position: relative;
}

#res-contact-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

#res-stack-wrapper {
    z-index: 1;
}

#res-text-form{
    display: flex;
    flex-direction: column;
    width: 70%;
    height: auto;
    left: 60%;
}

#res-submit-btn {
    width: 18%;
    margin-top: 10%;
    margin-left: 1%;
    background-color: #55B9D5;
    box-shadow: none !important;
}

#res-contact-text-1 {
    font-size: 1.5vw;
}

#res-text-form-div {
    margin-left: 16.5%;
    margin-top: -3%;
}

@media screen and (max-width: 1430px) and (min-width: 900px) {
    #res-contact-text-1 {
        font-size: 2.3vw;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 900px) {
    #res-submit-btn {
        width: 23%;
        margin-top: 14%;
    }
}

@media screen and (max-width: 900px) and (min-width: 700px) {
    #res-contact-text-1 {
        font-size: 3vw;
    }

    #res-submit-btn {
        width: 30%;
        margin-top: 17%;
    }
    #res-text-form{
        margin-left: 3.5%;
    }
}

@media screen and (max-width: 700px) and (min-width: 500px) {
    #res-contact-text-1 {
        font-size: 3.5vw;
    }

    #res-submit-btn {
        width: 45%;
        margin-top: 25%;
    }

    #res-text-form{
        margin-left: -3%;
        padding-top: 5%;
    }
}

@media screen and (max-width: 500px) and (min-width: 350px) {
    #res-contact-text-1 {
        font-size: 4.2vw;
    }

    #res-submit-btn {
        width: 60%;
        margin-top: 32%;
        margin-left: 1.5%;
    }

    #res-text-form{
        margin-left: -3%;
        padding-top: 5%;
    }
}

@media screen and (max-width: 500px) and (min-width: 400px) {
    #res-submit-btn {
        font-size: 2.7vw;
    }
}

@media screen and (max-width: 400px) and (min-width: 350px) {
    #res-submit-btn {
        font-size: 2.7vw;
    }
}

@media screen and (max-width: 350px) and (min-width: 100px) {
    #res-contact-text-1 {
        font-size: 4.6vw;
    }

    #res-submit-btn {
        width: 60%;
        margin-top: 40%;
        margin-left: 1.5%;
        font-size: 3.1vw;
    }

    #res-text-form{
        margin-left: -3%;
        padding-top: 5%;
    }
}

/*  */

#res-success-message {
    text-align: start;
    margin-left: 16%;    
}

#res-success-message-text {
    font-weight: 400;
    font-size: 1.4vw;
}

#res-success-message-icon {
    margin-bottom: -2%;
}

@media only screen and (max-width: 1100px) and (min-width:900px){

    #res-success-message {
        /* text-align: center; */
        /* margin-left: 12%; */
        /* margin-top: 3.5%; */
        
    }
    
    #res-success-message-text {
        font-weight: 400;
        font-size: 1.8vw;
    }
    
    #res-success-message-icon {
        margin-bottom: -3%;
    }
}

@media only screen and (max-width: 900px) and (min-width:800px){

    #res-success-message {
        margin-left: 19%;   
    }
    
    #res-success-message-text {
        font-weight: 400;
        font-size: 2.1vw;
    }
    
    #res-success-message-icon {
        margin-bottom: -3%;
    }
}

@media only screen and (max-width: 800px) and (min-width:700px){

    #res-success-message {
        margin-left: 25%;   
        margin-top: -2%;
        
    }
    
    #res-success-message-text {
        font-weight: 400;
        font-size: 2.3vw;
        max-width: fit-content;
        margin-left: -6%;
        margin-top: 3%;
    }

    #res-success-message-icon {
        margin-left: -10%;
        margin-bottom: -6%;
    }
}

@media only screen and (max-width: 700px) and (min-width:600px){

    #res-success-message {
        margin-left: 18%;   
        margin-top: -2%;
        
    }
    
    #res-success-message-text {
        font-weight: 400;
        font-size: 2.5vw;
        max-width: fit-content;
        margin-left: -6%;
        margin-top: 3%;
    }

    #res-success-message-icon {
        margin-left: -10%;
        margin-bottom: -6%;
    }
}

@media only screen and (max-width: 600px) and (min-width:500px){

    #res-success-message {
        margin-left: 18%;   
        margin-top: -2%;
        
    }
    
    #res-success-message-text {
        font-weight: 400;
        font-size: 2.5vw;
        max-width: fit-content;
        margin-left: -6%;
        margin-top: 3%;
    }

    #res-success-message-icon {
        margin-left: -10%;
        margin-bottom: -6%;
    }
}

@media only screen and (max-width: 500px) and (min-width:400px){

    #res-success-message {
        margin-left: 19%;   
        margin-top: -2%;
        
    }
    
    #res-success-message-text {
        font-weight: 400;
        font-size: 3.4vw;
        max-width: fit-content;
        margin-left: -6%;
        margin-top: 3%;
    }

    #res-success-message-icon {
        margin-left: -10%;
        margin-bottom: -6%;
    }
}

@media only screen and (max-width: 400px) and (min-width:300px){

    #res-success-message {
        margin-left: 19%;   
        margin-top: -2%;
        
    }
    
    #res-success-message-text {
        font-weight: 400;
        font-size: 3.7vw;
        max-width: fit-content;
        margin-left: -6%;
        margin-top: 3%;
    }

    #res-success-message-icon {
        margin-left: -10%;
        margin-bottom: -10%;
    }
}

@media only screen and (max-width: 300px) and (min-width:100px){

    #res-success-message {
        margin-left: 23%;   
        margin-top: -2%;
    }
    
    #res-success-message-text {
        font-weight: 400;
        font-size: 4vw;
        max-width: fit-content;
        margin-left: -6%;
        margin-top: 3%;
    }

    #res-success-message-icon {
        margin-left: -10%;
        margin-bottom: -10%;
    }
}