
.lab-own-ban {
    background: rgba(111, 216, 188, 0.1);
    height: fit-content;
    padding-bottom: 4.5vh;
}

#lab-own-ban-flex-1-heading-1 {
    font-size:4vw;
    font-weight:400;
}

#lab-own-ban-flex-1-heading-box-1 {
    max-width: 40vw;
    margin-bottom: 3%;
}

#lab-own-ban-flex-2-heading-box-2 {
    max-width: 68vw;
}

#lab-own-ban-flex-1-heading-2 {
    font-size:4vw;
    font-weight:500; 
}

#lab-own-ban-flex-2-text {
    font-size:1.4vw;
    font-weight:300; 
}

#lab-own-ban-flex-wrapper {
    display: flex;
    justify-content: center;
  }

#lab-own-ban-flex-1 {
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

}

#lab-own-ban-flex-2 {
    display: flex;
    flex-direction: row;
    margin-left: 26vw;
}

#cengiz-img {
    margin: 2vw;
    max-width: 20vw;
}


@media screen and (max-width: 1430px) and (min-width: 1100px) {
    #lab-own-ban-flex-1-heading-1 {
        font-size:4.6vw;
    }
    #lab-own-ban-flex-1-heading-2 {
        font-size: 4.6vw;
    }
    
    #lab-own-ban-flex-2-text {
        font-size:1.6vw;
    }
    #lab-own-ban-flex-1-heading-box-1 {
        max-width: 40vw;
    }
}


@media only screen and (max-width: 1100px) and (min-width: 900px){

    #lab-own-ban-flex-1-heading-box-1 {
        max-width: 50vw;
    }
    #lab-own-ban-flex-2-text {
        font-size:1.8vw;
    }
    #lab-own-ban-flex-1-heading-1 {
        font-size:5.2vw;
    }

    #lab-own-ban-flex-1-heading-2 {
        font-size:5.2vw;
    }
}

@media only screen and (max-width: 900px) and (min-width: 700px){
    #lab-own-ban-flex-2-text {
        font-size:2.3vw;
    }
    #lab-own-ban-flex-1-heading-1 {
        font-size:6.1vw;
    }
    #lab-own-ban-flex-1-heading-2 {
        font-size:6.1vw;
        
    }
    
    #lab-own-ban-flex-1-heading-box-1 {
        max-width: 50vw;
    }

    #cengiz-img {
        max-width: 27vw;
    }

    #lab-own-ban-flex-2 {
        margin-left: 19.5vw;
    }

}


@media only screen and (max-width: 700px) and (min-width: 600px){
    #lab-own-ban-flex-2-text {
        font-size:2.5vw;
    }
    #lab-own-ban-flex-1-heading-1 {
        font-size:6.6vw;
    }

    #lab-own-ban-flex-1-heading-box-1 {
        max-width: 65vw;
    }

    #lab-own-ban-flex-1-heading-2 {
        font-size:6.6vw;
    }

    #cengiz-img {
        max-width: 40vw;
    }

    #lab-own-ban-flex-2 {
        margin-left: 19.5vw;
        flex-direction: column;
        margin-left: 25vw;
    }

    #lab-own-ban-flex-2-heading-box-2 {
        max-width: 80vw;
    }
}

@media only screen and (max-width: 600px) and (min-width: 500px){
    #lab-own-ban-flex-2-text {
        font-size:2.9vw;
    }
    #lab-own-ban-flex-1-heading-1 {
        font-size:7.6vw;
    }

    #lab-own-ban-flex-1-heading-box-1 {
        max-width: 65vw;
    }

    #lab-own-ban-flex-1-heading-2 {
        font-size:7.6vw;
    }

    #cengiz-img {
        max-width: 40vw;
    }

    #lab-own-ban-flex-2 {
        margin-left: 19.5vw;
        flex-direction: column;
        margin-left: 25vw;
    }

    #lab-own-ban-flex-2-heading-box-2 {
        max-width: 80vw;
    }
}


@media only screen and (max-width: 500px) and (min-width: 400px){
    #lab-own-ban-flex-2-text {
        font-size:3.4vw;
    }
    #lab-own-ban-flex-1-heading-1 {
        font-size:8.3vw;
    }

    #lab-own-ban-flex-1-heading-box-1 {
        max-width: 80vw;
    }

    #lab-own-ban-flex-1-heading-2 {
        font-size:8.3vw;
    }

    #cengiz-img {
        max-width: 40vw;
    }

    #lab-own-ban-flex-2 {
        margin-left: 19.5vw;
        flex-direction: column;
        margin-left: 25vw;
    }

    #lab-own-ban-flex-2-heading-box-2 {
        max-width: 80vw;
    }
}


@media only screen and (max-width: 400px) and (min-width: 100px){
    #lab-own-ban-flex-2-text {
        font-size:3.9vw;
    }
    #lab-own-ban-flex-1-heading-1 {
        font-size:10vw;
    }

    #lab-own-ban-flex-1-heading-box-1 {
        max-width: 80vw;
    }

    #lab-own-ban-flex-1-heading-2 {
        font-size:10vw;
    }

    #cengiz-img {
        max-width: 40vw;
    }

    #lab-own-ban-flex-2 {
        margin-left: 19.5vw;
        flex-direction: column;
        margin-left: 25vw;
    }

    #lab-own-ban-flex-2-heading-box-2 {
        max-width: 80vw;
    }
}
