.vision-ban {
    display: flex;
    background-color: white;
    flex-direction: row;
    justify-content: flex-end;
}

#tooth-pt-video {
    margin-top: 3%;
    width: 45vw;
    pointer-events: none;
    padding-left: 25%;
}

#vision-text-flex {
    margin-top: 6%;
    /* margin-right:; */
}

#vision-description {
    max-width: 40vw;
    font-size:1.4vw;
    font-weight:400;
}

#vision-text-flex-1 {
    font-size:4vw;
    font-weight:500;
}


#vision-text-flex-2 {
    font-size:4vw;
    font-weight:500;
}


@media screen and (max-width: 1430px) and (min-width: 1100px){
#vision-description {
    font-size: 1.6vw;
}

#vision-text-flex-1 {
    font-size:4.6vw;
}

#vision-text-flex-2 {
    font-size:4.6vw;
}

#tooth-pt-video {
    margin-top: 6%;
    width: 50vw;
}

#vision-text-flex {
    margin-right:-4%;
}
}

@media screen and (max-width: 1100px) and (min-width: 900px){
    #vision-description{
        font-size: 1.8vw;
    }
    
    #vision-text-flex-1 {
        font-size:5.2vw;
    }
    
    
    #vision-text-flex-2 {
        font-size:5.2vw;
    }
    
    #tooth-pt-video {
        margin-top: 6%;
        width: 58vw;
        z-index: -1;
    }
    
    #vision-text-flex {
        margin-right:-17%;
        z-index: 1;
    }

    #vision-description{
        max-width: 45vw;
    }
    }


    @media screen and (max-width: 900px) and (min-width: 700px){
        
        #vision-description{
            max-width: 48vw;
        }
        #vision-description{
            font-size: 2.3vw;
        }
        
        #vision-text-flex-1 {
            font-size:6.1vw;
        }
        
        
        #vision-text-flex-2 {
            font-size:6.1vw;
        }
        
        #tooth-pt-video {
            margin-top: 12%;
            width: 60vw;
            z-index: -1;
        }
        
        #vision-text-flex {
            margin-right:-22%;
            z-index: 1;
        }
        }


@media screen and (max-width: 700px) and (min-width: 600px) {
    #vision-description{
        font-size:2.5vw;
        max-width: 75vw;
    }
    
    #vision-text-flex-1 {
        font-size:6.6vw;
    }

    #vision-text-flex-2 {
        font-size:6.6vw;
    }

    #vision-text-flex {
        margin-left: 14%;
        margin-bottom: 2%;
    }
    
    #tooth-pt-video {
        width: 44vh;
        padding-left: 5vw;
        text-align: center;
    }

    .vision-ban {
        flex-direction: column;
    }
}


@media screen and (max-width: 600px) and (min-width: 500px){
    .vision-ban {
        flex-direction: column;
    }

    #vision-text-flex-1 {
        font-size:7.6vw;
    }

    #vision-text-flex-2 {
        font-size:7.6vw;
    }
    #vision-text-flex {
        margin-left: 10.3%;
        margin-bottom: 2%;
    }

    #vision-description{
        font-size:2.9vw;
        max-width: 75vw;
    }
   
    #tooth-pt-video {
        width: 44vh;
        padding-left: 0vw;
        text-align: center;
    } 
}

@media screen and (max-width: 500px) and (min-width: 400px){
    .vision-ban {
        flex-direction: column;
    }
  
    #tooth-pt-video {
        width: 45vh;
        padding-left: 0vw;
        text-align: center;
    } 

    #vision-text-flex-1 {
        font-size:8.4vw;
    }

    #vision-text-flex-2 {
        font-size:8.4vw;
    }
    #vision-text-flex {
        margin-left: 12.5%;
        margin-bottom: 2%;
    }

    #vision-description{
        font-size:3.4vw;
        max-width: 75vw;
    }    
}

@media screen and (max-width: 400px) and (min-width: 100px){
    .vision-ban {
        flex-direction: column;
    }
  
    #tooth-pt-video {
        width: 45vh;
        padding-left: 0vw;
        text-align: center;
    } 

    #vision-text-flex-1 {
        font-size:10vw;
    }

    #vision-text-flex-2 {
        font-size:10vw;
    }
    #vision-text-flex {
        margin-left: 12.5%;
        margin-bottom: 2%;
    }

    #vision-description{
        font-size: 4vw;
        max-width: 75vw;
    }    
}