
.res-ban {
    width: 100%;
    height: 80vh;
    position: relative;
    z-index:0;
    object-fit: cover;
    max-height: fit-content;
}

@media only screen and (max-width: 1125px) and (min-width: 890px){
    .res-ban {
      height: 70vh;
    }
    #res-background-img {
        display: block;
        object-fit: cover;
        height: 70vh;
    }
}

@media only screen and (max-width: 890px) and (min-width: 400px){
  .res-ban {
    height: 60vh;
  }

  #res-background-img {
    display: block;
    object-fit: cover;
    height: 60vh;
}
}

@media (max-width: 400px) {
  .res-ban {
    height: 60vh;
  }
  #res-background-img {
    display: block;
    object-fit: cover;
    height: 60vh;
}
}

.res-ban:before {
    content: '';
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #55B9D5;
    opacity: 0.85;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

@media only screen and (min-width: 1125px){
    #res-background-img {
        display: block;
        height: 80vh;
        object-fit: cover;
    }
  }


#res-background-img {
    width: 100%;
}


.res-ban-overlay {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    position:absolute;
    width: fit-content;
    top:30%;
    left: -2%;
    z-index:1;
    color: rgb(255, 255, 255);
    font-size: larger;
}


.res-text-container {
    margin-top: -1%;
    width: 68%;
    display: inline-block;
    text-align: start;
}  

/* @media (max-width: 1200px) {
    .res-text-container {
        margin-top: 3%;
    }
} */
@media (max-height: 600px) {
    .res-text-container {
        margin-top: -3%;
    }
}

#res-text-container-heading-1{
    font-size: 4vw;
    width: 0;
    animation: typing 1s steps(23, end) forwards;
    margin-bottom: 0%
}


@keyframes typing {
    from { width: 100% }
    to { width: 100% }
  }


#res-text-container-heading-2{
    font-size: 1.4vw;
    font-weight: 400;
    opacity: 0;
    animation: text-visibilty 1s 1s forwards;
    padding-top: 4px;
}

@keyframes text-visibilty {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media screen and (max-width: 1430px) and (min-width:1100px){
  
    #res-text-container-heading-1{
      font-size: 4.6vw;
  }
    
  #res-text-container-heading-2{
    font-size: 1.6vw;
  }
  
  .res-ban-overlay {
    top:32.7%;
    left:-2.7%;
  }
  
    }

@media screen and (max-width: 1100px) and (min-width:900px){

    #res-text-container-heading-1{
        font-size: 5.2vw;
    }
    
    #res-text-container-heading-2{
    font-size: 1.8vw;

    }
    
    .res-ban-overlay {
        top:32.7%;
        left:-2.7%;
      }
    
    }

@media screen and (max-width: 900px) and (min-width:700px){
  
    #res-text-container-heading-1{
        font-size: 6.1vw;
    }
    
    #res-text-container-heading-2{
    font-size: 2.3vw;
    }

    .res-ban-overlay {
        top:33%;
        left:-2.5%;
      }
    }


@media screen and (max-width: 700px) and (min-width:600px){

    #res-text-container-heading-1{
        font-size: 6.6vw;
    }
    
    #res-text-container-heading-2{
    font-size: 2.5vw;
    }
    .res-ban-overlay {
        top:33%;
        left:-1%;
      }
    
    .res-text-container {
        width: 75%;
        display: inline-block;
    }  
    }
          
@media screen and (max-width: 600px) and (min-width:500px){

    #res-text-container-heading-1{
    font-size: 7.6vw;
}
    
    #res-text-container-heading-2{
        font-size: 2.9vw;

    }
    .res-ban-overlay {
        top:25%;
        left:11%;
      }
    .res-text-container {
        width: 75vw;
        display: inline-block;
        /* margin-left: 12.5%; */
        }  

    }
          
@media screen and (max-width: 500px) and (min-width:400px){

#res-text-container-heading-1{
    font-size: 8.3vw;
}

#res-text-container-heading-2{
font-size: 3.4vw;
}

.res-ban-overlay {
    top:24%;
    left:0%;
  }

.res-text-container {
    width: 75%;
    display: inline-block;
    /* margin-left: 2.4%; */
}  
}
          
@media screen and (max-width: 400px) and (min-width:100px){

    #res-text-container-heading-1{
    font-size: 10vw;
}
    
#res-text-container-heading-2{
    font-size: 4vw;

}
.res-ban-overlay {
    top:25%;
    left:1%;
  }

.res-text-container {
    width: 80%;
    display: inline-block;
    }  

}