.lab-res-ban {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 2%;
 }

#research-tag {
    width: 30%; 
    height: auto;
    position: relative;
    margin-right: 2.5%;
 }

#cad-tag {
    width: 30%; 
    height: auto;
    position: relative;
    margin-right: 2.5%;
}

#lab-tag {
    width: 30%; 
    height: auto;
    position: relative;
}

#research-img, #lab-img, #cad-img {
    width: 100%;
    height: auto;
    margin: 10px auto;
}


#research-img:hover, #lab-img:hover, #cad-img:hover  {
    filter: brightness(70%);
}


#research-tag-text, #lab-tag-text, #cad-tag-text {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    width: 80%;
    height: auto;
    margin-top: 8%;
    top: 29%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#research-img-text-1, #lab-img-text-1, #cad-img-text-1 {
    text-align:center;
    margin-top: 30%;
    color: #FFFFFF;
    font-size:2.7vw;
    font-weight:600;
}


#lab-img-text-1 {
    width: 70%;
    margin-left: 13%;
}

#research-img-text-2, #lab-img-text-2, #cad-img-text-2 {
    text-align: center;
    line-height: 1;
    margin-top: 10%;
    color: #FFFFFF;
    font-size:1.3vw;
    font-weight:300;
}

#research-img-text-3, #lab-img-text-3, #cad-img-text-3  {
    text-align: center;
    margin-top: 17%;
    color: #FFFFFF;
    font-size:1.3vw;
    font-weight:300;
  }


/* #lab-tag-text {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    width: 70%;
    height: auto;
    top: 29%;
    left: 50%;
    margin-top: 8%;
    transform: translate(-50%, -50%);
} */


/* #lab-img-text-1 {
    width: 60%;
    text-align:center;
    margin-left: 20%;
    margin-top: 30%;
    color: #FFFFFF;
    font-size:3vw;    
    font-weight:600;
  } */

/* #lab-img-text-2 {
    text-align: center;
    margin-top: 10%;
    line-height: 1;
    color: #FFFFFF;
    font-size:1.4vw;
    font-weight:300;
} */

/* #lab-img-text-3 {
    text-align: center;
    margin-top: 17%;
    color: #FFFFFF;
    font-size:1.4vw;
    font-weight:300;
  } */
       


 @media only screen and (max-width: 800px) {
    .lab-res-ban {
        flex-direction: column;
        justify-content:center;
        margin-left: 10%;
    }
    #research-tag, #lab-tag, #cad-tag {
        width: 90%;
    }
    #research-img-text-1, #lab-img-text-1, #cad-img-text-1 {
        /* margin-top: 30%; */
        font-size:7vw;
      }
    
    #research-img-text-2, #lab-img-text-2, #cad-img-text-2 {
        /* margin-top: 10%; */
        font-size:3vw;
    }
    
    #research-img-text-3, #lab-img-text-3, #cad-img-text-3 {
        /* margin-top: 30%; */
        font-size:3.5vw;
      }
}