
/*  */

#drop-parent-grid{
    margin-top: 5%;
}

#drop-title {
    padding-top: 6vw;
    margin-left: 11%;
}

#drop-title-1 {
    font-size: 6.1vw;
}

#drop-title-2 {
    font-size: 6.1vw;
}

#drop-btn-grid {
    display: flex;
    max-width: 30vw;
    margin-left: 14.5vw;
}

#drop-btn {
    text-align: start;
    justify-content: flex-start;
    font-size: 3.5vw;
    font-weight: 400;
    line-height: 33px;
  }

  #drop-line{
    width: 50vw;
    height: 0.18vw;
    background-color: rgba(0, 0, 0, 1);
    margin-left: 14vw;
    margin-top: 3%;
    margin-bottom: 3%;
  }

#drop-icon-btn {
    margin-top: 15%;
    margin-left: 30vw;
 }


 @media screen and (max-width: 550px) and (min-width: 400px) {
    #drop-title-1 {
        font-size: 7.8vw;
    }
    
    #drop-title-2 {
        font-size: 7.8vw;
    }

    #drop-btn {
        font-size: 4vw;
        line-height: 25px;
      }

    #drop-line{
        width: 55vw;
      }
 }

 @media screen and (max-width: 400px) and (min-width: 300px) {
    #drop-title-1 {
        font-size: 9.4vw;
    }
    
    #drop-title-2 {
        font-size: 9.4vw;
    }

    #drop-btn {
        font-size: 5vw;
        line-height: 22px;
      }

    #drop-line{
        width: 65vw;
      }
 }


 @media screen and (max-width: 300px) and (min-width: 100px) {
    #drop-title-1 {
        font-size: 10vw;
    }
    
    #drop-title-2 {
        font-size: 10vw;
    }

    #drop-btn {
        font-size: 5.7vw;
        line-height: 18px;
      }

    #drop-line{
        width: 75vw;
      }

      /* #drop-child-icon {
        width: 12vw;
    }

    #drop-text-head {
        font-size: 3.8vw;
    }
    
    #drop-text {
        font-size: 2.8vw;
    }
    #drop-text-flex {
        width: 55vw;
     } */
 }



 @media screen and (max-width: 800px) and (min-width: 500px) {
    #drop-text-flex {
        margin-left: 18vw;
        width: 50vw;
     }
    
    #drop-child-icon {
        width: 6vw;
        margin-top: 5%;
    }
    
    #drop-text-head {
        font-size: 3vw;
        margin-top: 1%;
        margin-bottom: -3%;
    }
    
    #drop-text {
        font-weight: 400;
        font-size: 2vw;
    }

 }



@media screen and (max-width: 500px) and (min-width: 400px) {
    #drop-child-icon {
        width: 8vw;
        margin-top: 5%;
    }

    #drop-text-head {
        font-size: 3.8vw;
        margin-top: 1%;
        margin-bottom: -3%;
    }
    
    #drop-text {
        font-size: 2.8vw;
    }
    #drop-text-flex {
        width: 55vw;
        margin-left: 18vw;

     }

     #drop-child-icon {
        width: 6vw;
        margin-top: 5%;
    }
    
}


@media screen and (max-width: 400px) and (min-width: 300px) {
    #drop-child-icon {
        width: 12vw;
        margin-top: 5%;
    }

    #drop-text-head {
        font-size: 4.3vw;
        margin-top: 1%;
        margin-bottom: -3%;
    }
    
    #drop-text {
        font-size: 3.1vw;
    }
    #drop-text-flex {
        width: 55vw;
        margin-left: 18vw;

     }
}

@media screen and (max-width: 300px) and (min-width: 100px) {
    #drop-child-icon {
        width: 12vw;
        margin-top: 5%;
    }

    #drop-text-head {
        font-size: 5.2vw;
        margin-top: 1%;
        margin-bottom: -3%;
    }
    
    #drop-text {
        font-size: 3.7vw;
    }
    #drop-text-flex {
        width: 55vw;
        margin-left: 18vw;

     }
}