
.lab-depart-parent {
    display: flex;
    flex-direction: column;
    margin-left: 20vw;
    width: 100%;
}

.depart-video-div {
    width: 65vw;
    margin-bottom: 5vw;
    display: flex;
    flex-direction: row;
}

/* #depart-img{
    display: none;
} */

#depart-video {
    width: 28vw;
}

#depart-child-2 {
    margin-left: 5vw;
    align-self: center;
}

#depart-heading {
    font-size: 1.8vw;
    font-weight: 600;
}

#depart-text {
    font-size: 1.1vw;
    margin-top: -3%;
    font-weight: 300;
}

@media only screen and (max-width: 1430px) and (min-width: 1100px){

    .lab-depart-parent {
        margin-left: 16vw;
    }

    #depart-video {
        width: 35vw;
    }

    #depart-heading {
        font-size: 2.3vw;
    }
    
    #depart-text {
        font-size: 1.5vw;
        margin-top: -3%;
    }
    #depart-child-2 {
        margin-top: -3%;
    }

    .depart-video-div { 
        width: 68vw;
    }

}

@media only screen and (max-width: 1100px) and (min-width: 900px){

    .lab-depart-parent {
        margin-left: 16vw;
    }

    #depart-video {
        width: 37vw;
    }

    #depart-heading {
        font-size: 2.8vw;
    }
    
    #depart-text {
        font-size: 1.75vw;
        margin-top: -3%;
    }
    .depart-video-div { 
        width: 72vw;
    }
    #depart-child-2 {
        margin-left: 3vw;
        margin-top: -4%;
    }

}

@media only screen and (max-width: 900px) and (min-width: 800px){

    .lab-depart-parent {
        margin-left: 16vw;
    }

    #depart-video {
        width: 37vw;
    }

    #depart-heading {
        font-size: 3vw;
    }
    
    #depart-text {
        font-size: 1.9vw;
        margin-top: -3%;
    }
    .depart-video-div { 
        width: 73vw;
    }
    #depart-child-2 {
        margin-left: 3vw;
        margin-top: -5%;
    }

}


@media only screen and (max-width: 800px) and (min-width: 600px){

    /* #depart-video {
        display: none;
    } */

    #depart-img{
        display: flex;
        width: 40vw;
        margin-top: 5%;
        /* margin-left: ; */
    }

    .lab-depart-parent {
        margin-left: 8vw;
        align-items: center;
    }

    #depart-heading {
        font-size: 4.2vw;
    }
    
    #depart-text {
        font-size: 2.3vw;
        margin-top: -5%;
    }

    .depart-video-div { 
        flex-direction: column;
    }
    #depart-child-2 {
        margin-left: 0vw;
        width: 50vw;
        align-self: flex-start;
    }

}



@media only screen and (max-width: 600px) and (min-width:400px){

    /* #depart-video {
        display: none;
    } */

    #depart-img{
        display: flex;
        width: 50vw;
        margin-top: 5%;
        /* margin-left: ; */
    }

    .lab-depart-parent {
        margin-left: 6vw;
        align-items: center;
    }

    #depart-heading {
        font-size: 5vw;
    }
    
    #depart-text {
        font-size: 2.8vw;
        margin-top: -5%;
    }

    .depart-video-div { 
        flex-direction: column;
    }
    #depart-child-2 {
        margin-left: 0vw;
        width: 52vw;
        align-self: flex-start;
    }

}


@media only screen and (max-width: 400px) and (min-width:100px){

    /* #depart-video {
        display: none;
    } */

    #depart-img{
        display: flex;
        width: 58vw;
        margin-top: 5%;
    }

    .lab-depart-parent {
        margin-left: 0vw;
        align-items: center;
    }

    #depart-heading {
        font-size: 6vw;
    }
    
    #depart-text {
        font-size: 3.5vw;
        margin-top: -5%;
    }

    .depart-video-div { 
        flex-direction: column;
    }
    #depart-child-2 {
        margin-left: 0vw;
        width: 61vw;
        align-self: flex-start;
    }

}