.lab-product-ban{
    padding-bottom: 3vw;
}

#lab-product-title {
    padding-top: 6vw;
    margin-left: 15vw;
}

#lab-product-title-1 {
    font-size: 4vw;
}

#lab-product-title-2 {
    font-size: 4vw;
}

#lab-product-text {
    margin-top: 3%;
    font-size: 1.4vw;
    font-weight: 400;
    max-width: 70vw;
    margin-bottom: 5vw;
}

#product-list-parent {
    display: flex;
    flex-direction: row;
    margin-left: 26vw;
    margin-bottom: -1.3vw;
}

#product-desc {
    max-width: 30vw;
    height: auto;
    margin-top: 3.5%;
}

#product-desc-head {
    font-size: 1.8vw;
}

#product-desc-head-div {
    padding-bottom: 1.7%;
}

#product-desc-subhead {
    font-size: 0.98vw;
    font-weight: 400;
}

#product-img {
    margin-right: 5vw;
    width: 15%;
}

@media only screen and (max-width: 1430px) and (min-width: 1100px){
    #lab-product-title-1 {
        font-size: 4.6vw;
    }
    
    #lab-product-title-2 {
        font-size: 4.6vw;
    }
    #lab-product-text {
        font-size: 1.6vw;
    }
    #product-img {
        width: 20%;
    }

    #product-desc-head {
        font-size: 2.4vw;
    }
    
    #product-desc-subhead {
        font-size: 1.4vw;
    }
}

@media only screen and (max-width: 1100px) and (min-width: 900px){
    #lab-product-title-1 {
        font-size: 5.2vw;
    }
    
    #lab-product-title-2 {
        font-size: 5.2vw;
    }
    #lab-product-text {
        font-size: 1.8vw;
    }
    #product-img {
        width: 25%;
    }

    #product-desc-head {
        font-size: 2.5vw;
    }
    
    #product-desc-subhead {
        font-size: 1.5vw;
    }
}


@media only screen and (max-width: 900px) and (min-width: 700px){
    #lab-product-title-1 {
        font-size: 6.1vw;
    }
    
    #lab-product-title-2 {
        font-size: 6.1vw;
    }
    #lab-product-text {
        font-size: 2.3vw;
    }

    #product-list-parent {
        margin-left: 23vw;
    }

    #product-img {
        width: 30%;
    }
   
    #product-desc-head {
        font-size: 2.9vw;
    }
    
    #product-desc-subhead {
        font-size: 1.7vw;
    }

    #product-desc {
        max-width: 38vw;
    }
}

@media only screen and (max-width: 700px) and (min-width: 600px){
    #lab-product-title-1 {
        font-size: 6.6vw;
    }
    
    #lab-product-title-2 {
        font-size: 6.6vw;
    }
    #lab-product-text {
        font-size: 2.5vw;
    }

    #product-list-parent {
        margin-left: 20vw;
    }

    #product-list-parent {
        margin-left: 20vw;
    }

    #product-img {
        width: 32%;
    }
   
    #product-desc-head {
        font-size: 2.8vw;
    }
    
    #product-desc-subhead {
        font-size: 2vw;
    }

    #product-desc {
        max-width: 35vw;
    }
}

@media only screen and (max-width: 600px) and (min-width: 500px){
    #lab-product-title-1 {
        font-size: 7.6vw;
    }
    
    #lab-product-title-2 {
        font-size: 7.6vw;
    }
    #lab-product-text {
        font-size: 2.9vw;
    }
}

@media only screen and (max-width: 500px) and (min-width: 400px){
    #lab-product-title-1 {
        font-size: 8.3vw;
    }
    
    #lab-product-title-2 {
        font-size: 8.3vw;
    }
    #lab-product-text {
        font-size: 3.4vw;
    }
}


@media only screen and (max-width: 400px) and (min-width: 100px){
    #lab-product-title-1 {
        font-size: 10vw;
    }
    
    #lab-product-title-2 {
        font-size: 10vw;
    }
    #lab-product-text {
        font-size: 3.9vw;
    }
}

/*  */

@media only screen and (max-width: 600px) and (min-width: 460px){
    #product-list-parent {
        flex-direction: column;
        margin-left: 30vw;
        margin-bottom: 1vw;
    }

    #product-img {
        width:50%;
    }
   
    #product-desc-head {
        font-size: 3.6vw;
    }
    
    #product-desc-subhead {
        font-size: 2.6vw;
    }

    #product-desc {
        max-width: 50vw;
    }
}

@media only screen and (max-width: 460px) and (min-width: 370px){
    #product-list-parent {
        flex-direction: column;
        margin-left: 25vw;
        margin-bottom: 1vw;
    }

    #product-img {
        width:60%;
    }
   
    #product-desc-head {
        font-size: 4.8vw;
    }
    
    #product-desc-subhead {
        font-size: 3.3vw;
    }

    #product-desc {
        max-width: 50vw;
    }
}


@media only screen and (max-width: 370px) and (min-width: 100px){

    #product-list-parent {
        flex-direction: column;
        margin-left: 25vw;
        margin-bottom: 1vw;
    }

    #product-img {
        width:60%;
    }
   
    #product-desc-head {
        font-size: 6vw;
    }
    
    #product-desc-subhead {
        font-size: 3.9vw;
    }

    #product-desc {
        max-width: 50vw;
    }
}