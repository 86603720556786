#nav-bar {
    background-color: #26466D;
    /* position:-webkit-sticky; */
    /* position:sticky; */
    top: 0;
    width: 100%;
    height: 9vh;
    z-index: 100;
  }

#icon-btn-navbar{
  color: white;
  margin-right: 0;
  margin-left: auto;
  margin-top: -0.5vh;
}
  
#icadlogo-navbar {
    position: absolute;
    height: 20.5vh;
    margin-top: -7.3vh;
  }
  

#icadlogo-navbar-drawer {
  position: absolute;
  height: 20.5vh;
  margin-top: -9vh;
  /* margin-top: -12.5%; */
  margin-left: -95vw;
}
  
  #navbar-buttons {
    position: absolute;
  }
  

  #navbar-buttons-stack {
    margin-left: 75vw;
    margin-top: 2vh;
  }

  #lab-btn {
    font-size: 2.2vh;
    color: white;
  }
  
  #research-btn {
    font-size: 2.2vh;
    color: white;
  }

.MuiSvgIcon-root {
    margin-top: 1.3vh;
    font-size: 2.2rem !important;
  }

@media screen and (max-width: 803px) {
    #icadlogo-navbar {
      margin-top: 1.8vh;
      margin-left: -1vw;
      height: 18.5vh;
    }
  }


@media screen and (max-width: 1800px) and (min-width:1420px){

    #navbar-buttons-stack {
      margin-left: 73vw;
    }
  
    }

@media screen and (max-width: 1400px) and (min-width:1200px){

  #navbar-buttons-stack {
    margin-left: 68vw;
  }

  }

@media screen and (max-width: 1200px) and (min-width:1000px){

  #navbar-buttons-stack {
    margin-left: 63vw;
  }

  }

@media screen and (max-width: 1000px) and (min-width:800px){

  #navbar-buttons-stack {
    margin-left: 60vw;
  }

  }

@media screen and (max-width: 599px) {

  /* #icadlogo-navbar {
    margin-top: 10vh;
  } */

  #icadlogo-navbar-drawer {
    position: absolute;
    height: 18vh;
    margin-top: -8vh;
    /* margin-top: -12.5%; */
    margin-left: -95vw;
  }
  }

.MuiDrawer-paper {
    background-color: #26466D !important;
    
      }

#lab-btn-drawer {
  color: white;
  font-size: 1.2rem;
  margin-right: 3vw;
}

#research-btn-drawer {
  color: white;
  font-size: 1.2rem;
  margin-right: 3vw;
}

#line {
  height: 0.5px;
  width:80%;
  background-color: #455365;
  margin-bottom: -1vh;
  margin-top: 0.3vh;
}