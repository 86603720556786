.image-carousel {
  display: flex;
  justify-content: space-around;
  width: 45vw;
  margin-left: 27.3vw;
  margin-top: 3%;
}

.image-carousel div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.image-carousel img {
  width: 100px;
  height: 100px;
  /* transition: transform 0.5s; */
}


.image-carousel .image-info {
margin-top: 5px;
text-align: center;
}


/* .image-carousel img.active {
  transform: scale(1.2);
} */


.image-carousel .title {
    font-weight: 300;
    font-size: 1.05vw;
    /* transition: font-weight 0.5s; */
    font-family: Inter, sans-serif;
    margin-top: 15%;
    color: #243B56;
  }
  
  .image-carousel .subtitle {
    color: rgb(111, 111, 111);
    /* transition: color 0.5s; */
    font-family: Inter, sans-serif;
    font-size: 0.7vw;
    margin-top: -8%;
  }

  /* .image-carousel div.active .title {
    font-weight: 500;
  }
  
  .image-carousel div.active .subtitle {
    color: #243B56;
  } */

  /* .image-carousel:hover {
    font-weight: 600;
  } */

  @media screen and (max-width: 1200px) and (min-width: 800px) {

    .image-carousel .title {
        font-size: 1.5vw;
    }

    .image-carousel .subtitle {
        font-size: 1vw;
    }

    .image-carousel {
        width: 60vw;
        margin-left: 20vw;
    }
  }


  @media screen and (max-width: 800px) and (min-width: 600px) {

    .image-carousel .title {
        font-size: 2.1vw;
        margin-top: 4%;
    }

    .image-carousel .subtitle {
        font-size: 1.5vw;
    }

    .image-carousel {
        width: 60vw;
        margin-left: 20vw;
        flex-direction: column;
    }
  }

  @media screen and (max-width: 600px) and (min-width: 400px) {

    .image-carousel .title {
        font-size: 2.95vw;
        margin-top: 4%;
    }

    .image-carousel .subtitle {
        font-size: 2.05vw;
    }

    .image-carousel {
        width: 60vw;
        margin-left: 20vw;
        flex-direction: column;
    }
  }

  @media screen and (max-width: 400px) and (min-width: 100px) {

    .image-carousel .title {
        font-size: 3.6vw;
        margin-top: 4%;
    }

    .image-carousel .subtitle {
        font-size: 2.8vw;
    }

    .image-carousel {
        width: 60vw;
        margin-left: 20vw;
        flex-direction: column;
    }
  }