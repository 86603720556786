
.lab-contact-ban {
    padding-left: 15vw;
    padding-top: 4vw;
}

#lab-contact-heading {
    margin-top: 2vw;
    font-size: 4vw;
    margin-bottom: 4vw;
    margin-bottom: 3.5vw;
}

#lab-contact-ban-text {
    max-width: 30vw;
}

#lab-contact-ban-text-1 {
    font-size: 1.4vw;
    font-weight: 400;
}

#lab-contact-flex-parent {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}

/* #lab-contact-flex-child1 {
    margin-top: 5%;
} */

#lab-contact-flex-child2 {
    margin-right: 4%;
    margin-top: -3%;
}
#lab-text-form{
    display: flex;
    flex-direction: column;
    width: 70%;
    height: auto;
    left: 60%;
    margin-right: 12vw;
    margin-top: 2vw;
}

#submit-btn {
    width: 48%;
    margin-left: 43%;
    margin-top: 20%;
    background-color: rgba(92, 176, 153, 1);
    box-shadow: none !important;
}


@media only screen and (max-width: 1430px) and (min-width: 1100px){
    #lab-contact-heading{
        font-size: 4.6vw;
    }
    
    #lab-contact-ban-text-1 {
        font-size: 1.6vw;
    }
}


@media only screen and (max-width: 1100px) and (min-width: 900px){
    #lab-contact-heading{
        font-size: 5.2vw;
    }
    #lab-contact-ban-text-1 {
        font-size: 1.8vw;
    }
}

@media only screen and (max-width: 1100px) and (min-width: 1000px){
    #submit-btn {
        font-size: 1.15vw;
    }
}

@media only screen and (max-width: 1900px) and (min-width: 1280px){

    #submit-btn {
        margin-left: 47%;
    }
}

@media only screen and (max-width: 1279px) and (min-width: 960px){
    #submit-btn {
        margin-left: 51.5%;
    }
}


@media only screen and (max-width: 960px) and (min-width: 800px){
    #submit-btn {
        margin-top: 25%;
        margin-left: 56%;
        font-size: 1.3vw;
    }
}

@media only screen and (max-width: 900px) and (min-width: 700px){

#lab-contact-heading{
    font-size: 6.1vw;
}

#lab-contact-ban-text-1 {
    font-size: 2.3vw;
}
}

@media only screen and (max-width: 800px) and (min-width: 700px){
    #lab-contact-ban-text {
        max-width: 55vw;
    }
    }

@media only screen and (max-width: 700px) and (min-width: 550px){

    #lab-contact-heading{
        font-size: 6.6vw;
    }
    #lab-contact-ban-text-1 {
        font-size: 2.5vw;
    }
    #lab-contact-ban-text {
        max-width: 70vw;
    }
    }

@media only screen and (max-width: 550px) and (min-width: 400px){

    #lab-contact-heading{
        font-size: 7.9vw;
    }
    
    #lab-contact-ban-text-1 {
        font-size: 3.2vw;
    }
    #lab-contact-ban-text {
        max-width: 70vw;
    }
    }

@media only screen and (max-width: 400px) and (min-width: 300px){

    #lab-contact-heading{
        font-size: 9.5vw;
    }
    #lab-contact-ban-text-1 {
        font-size: 3.7vw;
    }
    #lab-contact-ban-text {
        max-width: 60vw;
    }
    }

@media only screen and (max-width: 300px) and (min-width: 100px){

    #lab-contact-heading{
        font-size: 10vw;
    }
    #lab-contact-ban-text-1 {
        font-size: 3.9vw;
    }
    #lab-contact-ban-text {
        max-width: 70vw;
    }
    }

@media only screen and (max-width: 800px) and (min-width: 700px) {
    #lab-contact-flex-parent {
        margin-top: 10%;
        display: flex;
        flex-direction: column-reverse !important;
        justify-content:center;
    }

    #submit-btn {
        width: 38%;
        margin-left: 20.6%;
        margin-top: 20%;
        background-color: rgba(92, 176, 153, 1);
    }

    #lab-contact-flex-child1 {
    margin-top: 7%;
    }
    }

    @media only screen and (max-width: 700px) and (min-width: 500px) {
        #lab-contact-flex-parent {
            margin-top: 10%;
            display: flex;
            flex-direction: column-reverse !important;
            justify-content:center;
        }
    
        #submit-btn {
            width: 40%;
            margin-left: 48.7%;
            font-size: 2.1vw;
        }
    
        #lab-contact-flex-child1 {
        margin-top: 7%;
        }
        }


@media only screen and (max-width: 500px) and (min-width: 400px) {
    #lab-contact-flex-parent {
        margin-top: 10%;
        display: flex;
        flex-direction: column-reverse !important;
        justify-content:center;
    }

    #submit-btn {
        width: 42%;
        margin-left: 47.3%;
        margin-top: 27%;
        font-size: 2.4vw;
    }

    #lab-contact-flex-child1 {
    margin-top: 7%;
    }
    }

@media only screen and (max-width: 400px) and (min-width: 300px) {

    #lab-contact-flex-parent {
        margin-top: 10%;
        display: flex;
        flex-direction: column-reverse !important;
        justify-content:center;
    }

    #submit-btn {
        width: 55%;
        margin-left: 35%;
        margin-top: 30%;
        font-size: 2.8vw;
    }

    #lab-contact-flex-child1 {
    margin-top: 7%;
    }
    }


@media only screen and (max-width: 300px) and (min-width: 100px) {

    #lab-contact-flex-parent {
        margin-top: 10%;
        display: flex;
        flex-direction: column-reverse !important;
        justify-content:center;
    }

    #submit-btn {
        width: 67%;
        margin-left: 23%;
        margin-top: 38%;
        font-size: 3.2vw;
    }

    #lab-contact-flex-child1 {
    margin-top: 7%;
    }
    
    }

    /* ------ */

#lab-success-message {
    text-align: center;
    margin-right: 20%;
    margin-top: 5%;
    
}

#lab-success-message-text {
    font-weight: 400;
    font-size: 1.4vw;
}

#lab-success-message-icon {
    margin-bottom: -6%;
}

@media only screen and (max-width: 1100px) and (min-width:800px){

    #lab-success-message {
        text-align: center;
        margin-top: 3.5%;
        
    }
    
    #lab-success-message-text {
        font-weight: 400;
        font-size: 1.8vw;
    }
    
    #lab-success-message-icon {
        margin-bottom: -13%;
    }
}

@media only screen and (max-width: 800px) and (min-width:700px){

    #lab-success-message {
        text-align: start;
        margin-left: 6.7%;
        margin-top: -2%;
        
    }
    
    #lab-success-message-text {
        font-weight: 400;
        font-size: 2.3vw;
        max-width: fit-content;
        margin-left: -6%;
        margin-top: 3%;
    }
}

@media only screen and (max-width: 700px) and (min-width:600px){

    #lab-success-message {
        text-align: start;
        margin-left: 6.8%;
        margin-top: 2%;
        
    }
    
    #lab-success-message-text {
        font-weight: 400;
        font-size: 2.5vw;
        max-width: fit-content;
        margin-left: -6%;
        margin-top: 3%;
    }
}

@media only screen and (max-width: 600px) and (min-width:500px){

    #lab-success-message {
        text-align: start;
        margin-left: 7.2%;
        margin-top: 2%;        
    }
    
    #lab-success-message-text {
        font-weight: 400;
        font-size: 2.7vw;
        max-width: fit-content;
        margin-left: -6%;
        margin-top: 3%;
    }
}

@media only screen and (max-width: 500px) and (min-width:400px){

    #lab-success-message {
        text-align: start;
        margin-left: 8%;
        margin-top: 2%;
    }
    
    #lab-success-message-text {
        font-weight: 400;
        font-size: 3.2vw;
        max-width: fit-content;
        margin-left: -6%;
        margin-top: 3%;
    }
}

@media only screen and (max-width: 400px) and (min-width:300px){

    #lab-success-message {
        text-align: start;
        margin-left: 7.7%;
        margin-top: 2%;
        
    }
    
    #lab-success-message-text {
        font-weight: 400;
        font-size: 3.7vw;
        max-width: fit-content;
        margin-left: -5.5%;
        margin-top: 3%;
    }
}

@media only screen and (max-width: 300px) and (min-width:100px){

    #lab-success-message {
        text-align: start;
        margin-left: 14%;
        margin-top: 2%;        
    }
    
    #lab-success-message-text {
        font-weight: 400;
        font-size: 4vw;
        max-width: fit-content;
        margin-left: -10%;
        margin-top: 3%;
    }
}