.carousel-container {
  margin-top: 7%;
  padding: 20px 0;
  background-color: #55b9d51a;
  ;
}
.carousel-container .stack-container {
  width: 100%;
  height: calc(var(--width) * 0.356);
  position: relative;
}
@media (max-width: 768px) {

  .carousel-container .stack-container {
    height: calc(var(--width) * 0.9);
  }

}
.carousel-container .stack-top {
  position: relative;
  width: calc(var(--width) * 0.23);
  height: calc(var(--width) * 0.21);
  transition: transform 0.3s ease-in-out;
  margin-left: auto;
  margin-right: auto;
  transform: scale(0.626);
}
@media (max-width: 768px) {
  .carousel-container .stack-top {
    width: calc(var(--width) * 0.23 * 2.4);
    height: calc(var(--width) * 0.21 * 2.4);
  }
}
.carousel-container .stack-top img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.carousel-container .stack-top .stack-text-img {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
.carousel-container .stack-base {
  width: calc(var(--width) * 0.334);
  height: auto;
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 768px) {
  .carousel-container .stack-base {
    width: calc(var(--width) * 0.9);
  }
}
.carousel-container .stack-slide {
  height: calc(var(--width) * 0.356);
  position: relative;
}
@media (max-width: 768px) {
  .carousel-container .stack-slide {
    height: unset;
  }
}
.carousel-container .stack-slide .stack-slide-text {
  color: rgba(0, 0, 0, 1);
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out 0.4s;
  margin-top: calc(var(--width) * -0.02);
}
@media (max-width: 768px) {
  .carousel-container .stack-slide .stack-slide-text {
    display: none;
  }
}
.carousel-container .stack-slide .stack-slide-text h5 {
  font-family: "Public Sans";
  font-weight: 400;
  font-size: 2vw;
  margin: 0;
  text-align: center;
}
.carousel-container .stack-slide .stack-slide-text h5 b {
  font-weight: 700;
}
.carousel-container .stack-slide .stack-slide-text p {
  max-width: calc(var(--width) * 0.23);
  font-family: "Inter";
  font-weight: 300;
  font-size: 1.1vw;
  color: rgba(0, 0, 0, 1);
  margin: 20px auto 0 auto;
  white-space: pre-wrap;
  text-align: center;
}
.carousel-container .slick-center .stack-slide .stack-top {
  transform: scale(1);
}
.carousel-container .slick-center .stack-slide .stack-text-img {
  opacity: 1;
}
.carousel-container .slick-center .stack-slide .stack-slide-text {
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}
.carousel-container .slick-slide {
  cursor: pointer;
}
.carousel-container .slick-slide:not(.slick-active) .stack-slide-text {
  opacity: 0;
}
.carousel-container .navigation {
  position: relative;
  margin: 50px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  max-width: calc(100% - 200px);
}
@media (max-width: 768px) {
  .carousel-container .navigation {
    width: 90%;
    max-width: unset;
  }
}
.carousel-container .navigation .text-slide-container {
  width: 100%;
}
.carousel-container .navigation-button-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 768px) {
  /* .carousel-container .navigation-button-container {
    margin-top: 8px;
    position: relative;
    width: 60%;
    top: unset;
    transform: unset;
  } */

  .carousel-container .navigation-button-container {
    justify-content: space-evenly;
    top: 0%;
  }

}
.carousel-container .navigation-button-container button img {
  width: 25px;
  height: auto;
}
.carousel-container .navigation-button-container button:first-child {
  transform: translateX(-200%);
}
@media (max-width: 768px) {
  .carousel-container .navigation-button-container button:first-child {
    transform: none;
  }
}
.carousel-container .navigation-button-container button:last-child {
  transform: translateX(200%) scaleX(-1);
}
@media (max-width: 768px) {
  .carousel-container .navigation-button-container button:last-child {
    transform: scaleX(-1);
  }
}
.carousel-container button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.carousel-container .text-slide {
  min-height: 255px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.carousel-container .text-slide h5 {
  font-family: "Public Sans";
  font-weight: 400;
  font-size: 2.8vw;
  margin: 0;
}
.carousel-container .text-slide h5 b {
  font-weight: 700;
}
/* @media (max-width: 768px) {
  .carousel-container .text-slide h5 {
    font-size: 24px;
  }
} */
.carousel-container .text-slide p {
  font-family: "Inter";
  font-weight: 300;
  font-size: 1.4vw;
  line-height: 140%;
  color: rgba(0, 0, 0, 1);
  margin-top: 20px;
  white-space: pre-wrap;
}
/* @media (max-width: 768px) {
  .carousel-container .text-slide p {
    font-size: 18px;
  }
} */
.carousel-container img {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.carousel-container .learn-more {
  display: flex;
  height: 50px;
  border-radius: 25px;
  background: #55B9D5;
  align-items: center;
  padding: 0 22px;
  /* margin-left: auto; */
  margin-right: 5%;
  font-size: 1vw;
  font-family: "Inter";
  gap: 10px;
}
@media (max-width: 768px) {
  .carousel-container .learn-more {
    font-size: 18px;
    height: 45px;
    margin-top: -3%;
  }

  .carousel-container .navigation-button-container button img {
    width: 18px;
  }
}
.carousel-container .learn-more img {
  width: 20px;
  height: auto;
}
/* @media (max-width: 768px) {
  .carousel-container .learn-more img {
    font-size: 20px;
  }
} */


/*  */

#res-stack-title-1 {
  font-size: 4vw;
}

#res-stack-title-2 {
  font-size: 4vw;
}

#res-stack-text {
  font-size: 1.4vw;
  font-weight: 400;
}

#res-stack-title {
  max-width: 70vw;
  margin-top: 5%;
  margin-left: 16%;
  margin-bottom: 6%;
}

@media screen and (max-width: 1430px) and (min-width: 1100px) {
  #res-stack-title-1 {
    font-size: 4.6vw;
  }
  
  #res-stack-title-2 {
    font-size: 4.6vw;
  }
  
  #res-stack-text {
    font-size: 1.6vw;
  }

  .carousel-container .stack-slide .stack-slide-text h5  {
    font-size: 2.2vw;
  }
  .carousel-container .stack-slide .stack-slide-text p {
    font-size: 1.25vw;
    margin-top: 1%;
  }
  .carousel-container .text-slide h5 {
    font-size: 3.5vw;
  }
  .carousel-container .text-slide p {
    font-size: 1.7vw;
  }

  .carousel-container .learn-more {
    height: 40px;
    margin-right: 5%;
    font-size: 1.5vw;
  }

  .carousel-container .learn-more img {
    width: 22px;
    height: auto;
  }
}

@media screen and (max-width: 1100px) and (min-width: 900px) {
  #res-stack-title-1 {
    font-size: 5.2vw;
  }
  
  #res-stack-title-2 {
    font-size: 5.2vw;
  }
  
  #res-stack-text {
    font-size: 1.8vw;
  }

  .carousel-container .stack-slide .stack-slide-text h5  {
    font-size: 2.2vw;
  }
  .carousel-container .stack-slide .stack-slide-text p {
    font-size: 1.3vw;
    margin-top: 1%;
  }
  .carousel-container .text-slide h5 {
    font-size: 3.7vw;
  }
  .carousel-container .text-slide p {
    font-size: 1.9vw;
  }

  .carousel-container .learn-more {
    height: 40px;
    margin-right: 5%;
    font-size: 1.5vw;
  }

  .carousel-container .learn-more img {
    width: 22px;
    height: auto;
  }
}

@media screen and (max-width: 900px) and (min-width: 700px) {
  #res-stack-title-1 {
    font-size: 6.1vw;
  }
  
  #res-stack-title-2 {
    font-size: 6.1vw;
  }
  
  #res-stack-text {
    font-size: 2.3vw;
  }

  #res-stack-title {
    margin-left: 18.5%;
  }

}

@media screen and (max-width: 900px) and (min-width: 768px) {

  .carousel-container .stack-slide .stack-slide-text h5  {
    font-size: 2.6vw;
  }
  .carousel-container .stack-slide .stack-slide-text p {
    font-size: 1.6vw;
    margin-top: 1%;
  }
  .carousel-container .text-slide h5 {
    font-size: 4.2vw;
  }
  .carousel-container .text-slide p {
    font-size: 2vw;
  }

  .carousel-container .learn-more {
    height: 38px;
    margin-right: 5%;
    font-size: 1.9vw;
  }

  .carousel-container .learn-more img {
    width: 22px;
    height: auto;
  }
}

@media screen and (max-width: 768px) and (min-width: 500px) {

  .carousel-container .text-slide h5 {
    font-size: 5.3vw;
  }
  .carousel-container .text-slide p {
    font-size: 2.4vw;
    max-width: 70vw;
    text-align: center;
    margin-left: 11%;
  }

  .carousel-container .learn-more {
    height: 37px;
    margin-right: 0%;
    font-size: 2.4vw;
    gap: 5px;
  }

  .carousel-container .learn-more img {
    width: 2.8vw;
    height: auto;
  }
}

@media screen and (max-width: 500px) and (min-width: 350px) {

  .carousel-container .text-slide h5 {
    font-size: 6vw;
  }
  .carousel-container .text-slide p {
    font-size: 3.2vw;
    max-width: 70vw;
    text-align: center;
    margin-left: 11%;
  }

  .carousel-container .learn-more {
    height: 35px;
    margin-right: 0%;
    font-size: 3vw;
    gap: 5px;
  }

  .carousel-container .learn-more img {
    width: 3vw;
    height: auto;
  }
}

@media screen and (max-width: 350px) and (min-width: 100px) {

  .carousel-container .text-slide h5 {
    font-size: 8vw;
  }
  .carousel-container .text-slide p {
    font-size: 3.9vw;
    max-width: 70vw;
    text-align: center;
    margin-left: 11%;
  }

  .carousel-container .learn-more {
    height: 30px;
    margin-right: 0%;
    font-size: 4vw;
    gap: 5px;
  }

  .carousel-container .learn-more img {
    width: 4vw;
    height: auto;
  }
}


@media screen and (max-width: 700px) and (min-width: 500px) {
  #res-stack-title-1 {
    font-size: 6.6vw;
  }
  
  #res-stack-title-2 {
    font-size: 6.6vw;
  }
  
  #res-stack-text {
    font-size: 2.5vw;
  }

  #res-stack-title {
    max-width: 80vw;
    margin-left: 11%;
  }
}

@media screen and (max-width: 500px) and (min-width: 350px) {
  #res-stack-title-1 {
    font-size: 8.3vw;
  }
  
  #res-stack-title-2 {
    font-size: 8.3vw;
  }
  
  #res-stack-text {
    font-size: 3.4vw;
  }

  #res-stack-title {
    max-width: 80vw;
    margin-left: 11%;
  }
}

@media screen and (max-width: 350px) and (min-width: 100px) {
  #res-stack-title-1 {
    font-size: 10vw;
  }
  
  #res-stack-title-2 {
    font-size: 10vw;
  }
  
  #res-stack-text {
    font-size: 4vw;
  }

  #res-stack-title {
    max-width: 80vw;
    margin-left: 11%;
  }
}

/* #next-btn {
  height: 10px;
} */
