
.print-ban {
    /* height: auto 1vw; */
    padding-left: 15vw;
    padding-top: 4vw;
    padding-bottom: 4vw;
    background:rgba(111, 216, 188, 0.1);

}

#print-ban-heading {
    margin-top: 2vw;
    margin-bottom: 3.5vw;
}

#print-ban-heading-1 {
    font-size: 4vw;
}

#print-ban-heading-2 {
    font-size: 4vw;
}

#print-ban-text-1 {
    font-size: 1.4vw;
    font-weight: 400;
}

#print-ban-text {
    max-width: 70vw;
}

#print-ban-text-list-item {
    margin-bottom: 1vw;
    font-size: 1.4vw;
}

@media only screen and (max-width: 1430px) and (min-width: 1100px){
    #print-ban-heading-1 {
        font-size: 4.6vw;
    }
    
    #print-ban-heading-2 {
        font-size: 4.6vw;
    }
    
    #print-ban-text-1 {
        font-size: 1.6vw;
    }
    
    #print-ban-text-list-item {
        font-size: 1.6vw;
    }
}


@media only screen and (max-width: 1100px) and (min-width: 900px){
    #print-ban-heading-1 {
        font-size: 5.2vw;
    }
    
    #print-ban-heading-2 {
        font-size: 5.2vw;
    }
    
    #print-ban-text-1 {
        font-size: 1.8vw;
    }
    
    #print-ban-text-list-item {
        font-size: 1.8vw;
    }
}

@media only screen and (max-width: 900px) and (min-width: 700px){
    #print-ban-heading-1 {
        font-size: 6.1vw;
    }
    
    #print-ban-heading-2 {
        font-size: 6.1vw;
    }
    
    #print-ban-text-1 {
        font-size: 2.3vw;
    }
    
    #print-ban-text-list-item {
        font-size: 2.3vw;
    }
}

@media only screen and (max-width: 700px) and (min-width: 550px){
    #print-ban-heading-1 {
        font-size: 6.6vw;
    }
    
    #print-ban-heading-2 {
        font-size: 6.6vw;
    }
    
    #print-ban-text-1 {
        font-size: 2.5vw;
    }
    
    #print-ban-text-list-item {
        font-size: 2.5vw;
    }
}

@media only screen and (max-width: 550px) and (min-width: 400px){
    #print-ban-heading-1 {
        font-size: 7.9vw;
    }
    
    #print-ban-heading-2 {
        font-size: 7.9vw;
    }
    
    #print-ban-text-1 {
        font-size: 3.2vw;
    }
    
    #print-ban-text-list-item {
        font-size: 3.2vw;
    }
}

@media only screen and (max-width: 400px) and (min-width: 300px){
    #print-ban-heading {
        max-width: 70vw;
    }

    #print-ban-heading-1 {
        font-size: 9.5vw;
    }
    
    #print-ban-heading-2 {
        font-size: 9.5vw;
    }
    
    #print-ban-text-1 {
        font-size: 3.7vw;
    }
    
    #print-ban-text-list-item {
        font-size: 3.7vw;
    }
}

@media only screen and (max-width: 300px) and (min-width: 100px){
    
    #print-ban-heading {
        max-width: 70vw;
    }

    #print-ban-heading-1 {
        font-size: 10vw;
    }
    
    #print-ban-heading-2 {
        font-size: 10vw;
    }
    
    #print-ban-text-1 {
        font-size: 3.9vw;
    }
    
    #print-ban-text-list-item {
        font-size: 3.9vw;
    }
}