.contact-ban-parent {
   background-color: #f7f7f7;
   margin-top: 5%;
   display: flex;
   flex-direction: row;
   padding-left: 3%;
}

#contact-ban-child-left {
    width: 50%; 
    height: auto;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 6%;
}

#contact-ban-child-right {
    width: 50%; 
    height: auto;
    position: relative;
}

#contact-ban-child-left-text {
    width: 100%;
    height: auto;
    margin: 0 auto;
}

#joinus-text-1 {
    
    margin-left: 15%;
    /* margin-top: 15%; */
    font-size:4vw;
    font-weight:400;
}

#joinus-text-2{
    margin-left: 15%;
    margin-top: -5%;
    max-width: 60%;
    font-size:1.4vw;
    font-weight:400;
}

#contact-img {
    width: 100%;
    height: auto;
    margin: 0 auto;
    opacity: 0.25;
}

/* #contact-form-box {
    position: absolute;
    z-index: 1;
    width: 70%;
    height: auto;
    margin-top: 8%;
    top: 29%;
    left: 40%;
    transform: translate(-50%, -50%);
} */

#text-form{
    position: absolute;
    z-index: 1;
    width: 70%;
    height: auto;
    top: 37%;
    left: 60%;
    margin-top: 8%;
    transform: translate(-50%, -50%);
}

#touch-btn {
    width: 33%;
    margin-left: 18%;
    margin-top: 13%;
    background-color: #55bad5;
    box-shadow: none;
    color: white;
}
.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root{
    background-color: white !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    background-color: white !important;
    height: 0.8em !important;
}
@media screen and (max-width: 960px) and (min-width: 800px){
    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        height: 0.2em !important;
    }
}

@media screen and (max-width: 800px) and (min-width: 700px){
    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        height: 0.4em !important;
    }
}

@media screen and (max-width: 700px) and (min-width: 400px){
    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        height: 0.6em !important;
    }
}

@media screen and (max-width: 400px) and (min-width: 100px){
    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        height: 0.5em !important;
    }
}


@media screen and (max-width: 1280px) and (min-width: 960px) {
    #touch-btn {
        width: 40%;
        margin-left: 22%;
        margin-top: 18%;
    }
}
@media screen and (max-width: 960px) and (min-width: 800px) {
    #touch-btn {
        width: 43%;
        margin-left: 28%;
        margin-top: 25%;
        font-size: 1.3vw;
    }
}

@media screen and (max-width: 800px) and (min-width: 700px) {
    #touch-btn {
        font-size: 1.5vw;
        font-weight: 600;
        width: 65%;
        margin-top: 40%;
        margin-left: 37%;
    }
}

@media screen and (max-width: 700px) and (min-width: 500px) {
    #touch-btn {
        font-size: 2vw;
        font-weight: 600;
        width: 90%;
        margin-top: 50%;
        margin-left: 55%;
    }
}

@media screen and (max-width: 500px) and (min-width: 400px) {
    #touch-btn {
        font-size: 2vw;
        font-weight: 600;
        width: 100%;
        margin-top: 70%;
        margin-left: 55%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) {
    #touch-btn {
        font-size: 2vw;
        font-weight: 600;
        width: 100%;
        margin-top: 85%;
        margin-left: 55%;
    }
}

@media screen and (max-width: 300px) and (min-width: 100px) {
    #touch-btn {
        font-size: 3.5vw;
        font-weight: 600;
        width: 90%;
        margin-top: 63%;
        margin-left: 15%;
    }
}

@media only screen and (max-width: 800px)  {
    
    .contact-ban-parent {
        flex-direction: column;
        justify-content:center;
        padding-left: 28%;
    }

    #contact-img {
        visibility: hidden;
    }

    #joinus-text-1 {
        font-size:6vw;
        font-weight:300;
    }
    
    #joinus-text-2{
        margin-top: -3%;
        /* margin-bottom: 10%; */
        max-width: 100%;
        font-size: 2.1vw;
        font-weight: 300;
    }


    .css-ehenyq .MuiTextField-root {
        width: 34vw !important ; 
    }

    #text-form{
        left: 44%;
        /* margin-top: 8%; */
    }


    #temp-text {
        font-size: 2.1vw;
        margin-left: 7%;
        font-weight: 100;
        margin-top: -40%;
    }
    
    #temp-text-1 {
        font-size: 3vw;
        margin-left: 7%;
    }
}
@media only screen and (max-width: 700px) and (min-width: 600px){
    .contact-ban-parent {
        flex-direction: column;
        justify-content:center;
    }

    #joinus-text-1 {
        font-size:7vw;
        font-weight:300;
    }
    
    #joinus-text-2{
        margin-top: -3%;
        max-width: 100%;
        font-size: 2.4vw;
        font-weight: 300;
    }

     #text-form{
        left: 40%;
    }

    #temp-text {
        font-size: 2.4vw;
        margin-left: 14%;
        font-weight: 100;
        margin-top: -40%;
    }
    
    #temp-text-1 {
        font-size: 3.3vw;
        margin-left: 14%;
    }

}

@media only screen and (max-width: 600px) and (min-width: 500px){
    
     .css-ehenyq .MuiTextField-root {
        width: 40vw !important ; 
    }

    #text-form{
        left: 40%;
    }


    #joinus-text-1 {
        font-size:9vw;
        font-weight:300;
    }
    
    #joinus-text-2{
        /* margin-top: -3%; */
        /* margin-bottom: 30%; */
        max-width: 100vw;
        font-size: 2.9vw;
        font-weight: 300;
    }

    #temp-text {
        font-size: 2.9vw;
        margin-left: 14%;
        font-weight: 100;
        margin-top: -40%;
    }
    
    #temp-text-1 {
        font-size: 3.7vw;
        margin-left: 14%;
    }

}

@media only screen and (max-width: 500px) and (min-width: 450px){
    

     .css-ehenyq .MuiTextField-root {
        width: 48vw !important ; 
    }

    #text-form{
        left: 34%;
    }

    #joinus-text-1 {
        font-size:11vw;
        font-weight:300;
        width: max-content;
    }
    
    #joinus-text-2{
        /* margin-top: -3%; */
        /* margin-bottom: 40%; */
        max-width: 100vw;
        font-size: 3.2vw;
        font-weight: 300;
    }


     #text-form{
        left: 32%;
    }

    #temp-text {
        font-size: 3.2vw;
        margin-left: 26%;
        font-weight: 100;
        margin-top: -40%;
    }
    
    #temp-text-1 {
        font-size: 4.2vw;
        margin-left: 26%;
    }

}

@media only screen and (max-width: 450px) and (min-width: 400px){
    

     .css-ehenyq .MuiTextField-root {
        width: 48vw !important ; 
    }

    #text-form{
        left: 34%;
    }

    #joinus-text-1 {
        font-size:11vw;
        font-weight:300;
        width: max-content;
    }
    
    #joinus-text-2{
        /* margin-top: -3%;
        margin-bottom: 40%; */
        max-width: 100vw;
        font-size: 3.2vw;
        font-weight: 300;
    }

     #temp-text {
        font-size: 3.2vw;
        margin-left: 26%;
        font-weight: 100;
        margin-top: -40%;
    }
    
    #temp-text-1 {
        font-size: 4.2vw;
        margin-left: 26%;
    }
     

}


@media only screen and (max-width: 400px) and (min-width: 340px){
    


     .css-ehenyq .MuiTextField-root {
        width: 48vw !important ; 
    }

    #text-form{
        left: 34%;
    }

    #joinus-text-1 {
        font-size:12vw;
        font-weight:300;
        width: max-content;
    }
    
    #joinus-text-2{
        /* margin-top: -3%;
        margin-bottom: 50%; */
        max-width: 100vw;
        font-size: 3.5vw;
        font-weight: 300;
    }


     #temp-text {
        font-size: 3.5vw;
        margin-left: 23%;
        font-weight: 100;
        margin-top: -20%;
    }
    
    #temp-text-1 {
        font-size: 4.5vw;
        margin-left: 23%;
    }

}
  

@media only screen and (max-width: 340px) and (min-width: 300px){
    

     .css-ehenyq .MuiTextField-root {
        width: 55vw !important ; 
    }

    #text-form{
        left: 30%;
    }

    #joinus-text-1 {
        font-size:13vw;
        font-weight:300;
        width: max-content;
    }
    
    #joinus-text-2{
        /* margin-top: -3%;
        margin-bottom: 60%; */
        max-width: 100vw;
        font-size: 3.8vw;
        font-weight: 300;
    }


     #temp-text {
        font-size: 3.8vw;
        margin-left: 28%;
        font-weight: 100;
        margin-top: -20%;
    }
    
    #temp-text-1 {
        font-size: 5vw;
        margin-left: 28%;
    }

}

@media only screen and (max-width: 300px) and (min-width: 250px){
    

     .css-ehenyq .MuiTextField-root {
        width: 55vw !important ; 
    }

    #text-form{
        left: 30%;
    }

    #joinus-text-1 {
        font-size:14vw;
        font-weight:300;
        width: max-content;
    }
    
    #joinus-text-2{
        width: 70vw;
        font-size: 4.5vw;
        font-weight: 300;
    }


     #contact-ban-child-right {
        width: 90%;
     }

     #temp-text {
        font-size: 4.5vw;
        margin-left: 20%;
        font-weight: 100;
        margin-top: -20%;
    }
    
    #temp-text-1 {
        font-size: 5.5vw;
        margin-left: 20%;
    }
}

@media only screen and (max-width: 250px) and (min-width: 150px){
    

     .css-ehenyq .MuiTextField-root {
        width: 55vw !important ; 
    }

    #text-form{
        left: 30%;
    }

    #joinus-text-1 {
        font-size:14vw;
        font-weight:300;
        width: max-content;
    }
    
    #joinus-text-2{
        width: 100vw;
        font-size: 4.5vw;
        font-weight: 300;
    }

     #contact-ban-child-right {
        width: 90%;
     }

     #temp-text {
        /* max-width: 100vw !important; */
        font-size: 4.5vw;
        margin-left: 20%;
        font-weight: 100;
        margin-top: -20%;
    }
    
    #temp-text-1 {
        font-size: 5.5vw;
        margin-left: 20%;
    }
}

@media only screen and (min-width:800px) {
    #temp-text {
        font-size: 1.6vw;
        margin-left: 13%;
        font-weight: 100;
    }
    
    #temp-text-1 {
        font-size: 2vw;
        margin-left: 26%;
    }
}


@media only screen and (max-width: 800px) and (min-width:700px){
    #contact-ban-child-right {
        margin-top: 13%;
     }

     .contact-ban-parent {
        padding-bottom: 10%;
     }

     #contact-ban-child-left  {
        width:70%;
        margin-left: -6%;
     }
}

@media only screen and (max-width: 700px) and (min-width:550px){
    #contact-ban-child-right {
        margin-top: 20%;
        margin-left: -6%;
     }

     .contact-ban-parent {
        padding-bottom: 15%;
     }

     #contact-ban-child-left  {
        width: 80%;
        margin-left: -14.5%;
     }
}

@media only screen and (max-width: 550px) and (min-width:500px){
    #contact-ban-child-right {
        margin-top: 25%;
        margin-left: -5%;
     }

     .contact-ban-parent {
        padding-bottom: 20%;
     }

     #contact-ban-child-left  {
        width: 90%;
        margin-left: -14.5%;
     }
}

@media only screen and (max-width: 500px) and (min-width:400px){
    #contact-ban-child-right {
        margin-top: 33%;
        margin-left: -2.5%;
     }

     .contact-ban-parent {
        padding-bottom: 25%;
     }

     #contact-ban-child-left  {
        width: 90%;
        margin-left: -16%;
     }
}

@media only screen and (max-width: 400px) and (min-width:300px){
    #contact-ban-child-right {
        margin-top: 40%;
        margin-left: -2.5%;
     }

     .contact-ban-parent {
        padding-bottom: 35%;
     }

     #contact-ban-child-left  {
        width: 90%;
        margin-left: -15.3%;
     }
}

@media only screen and (max-width: 300px) and (min-width:100px){
    #contact-ban-child-right {
        margin-top: 45%;
        margin-left: -4%;
     }

     .contact-ban-parent {
        padding-bottom: 40%;
     }

     #contact-ban-child-left  {
        width: 80%;
        margin-left: -20%;
     }
}

#success-message {
    text-align: center;
    margin-left: 12%;
    margin-top: 5%;
    
}

#success-message-text {
    font-weight: 400;
    font-size: 1.4vw;
}

#success-message-icon {
    margin-bottom: -6%;
}

@media only screen and (max-width: 1100px) and (min-width:800px){

    #success-message {
        text-align: center;
        /* margin-left: 12%; */
        margin-top: 3.5%;
        
    }
    
    #success-message-text {
        font-weight: 400;
        font-size: 1.4vw;
    }
    
    #success-message-icon {
        margin-bottom: -13%;
    }
}

@media only screen and (max-width: 800px) and (min-width:700px){

    #success-message {
        text-align: start;
        /* align-items: center; */
        /* margin-left: -30%; */
        margin-top: -2%;
        
    }
    
    #success-message-text {
        font-weight: 300;
        font-size: 2.1vw;
        max-width: fit-content;
        margin-left: -6%;
        margin-top: 3%;
    }
}

@media only screen and (max-width: 700px) and (min-width:600px){

    #success-message {
        text-align: start;
        margin-left: 6%;
        margin-top: 2%;
        margin-bottom: -8%;
        
    }
    
    #success-message-text {
        font-weight: 300;
        font-size: 2.4vw;
        max-width: fit-content;
        margin-left: -6%;
        margin-top: 3%;
    }
}

@media only screen and (max-width: 600px) and (min-width:500px){

    #success-message {
        text-align: start;
        margin-left: 8%;
        margin-top: 2%;
        margin-bottom: -8%;
        
    }
    
    #success-message-text {
        font-weight: 300;
        font-size: 2.9vw;
        max-width: fit-content;
        margin-left: -6%;
        margin-top: 3%;
    }
}

@media only screen and (max-width: 500px) and (min-width:400px){

    #success-message {
        text-align: start;
        margin-left: 8%;
        margin-top: 2%;
        margin-bottom: -8%;
        
    }
    
    #success-message-text {
        font-weight: 300;
        font-size: 3.2vw;
        max-width: fit-content;
        margin-left: -6%;
        margin-top: 3%;
    }
}

@media only screen and (max-width: 400px) and (min-width:300px){

    #success-message {
        text-align: start;
        margin-left: 8%;
        margin-top: 2%;
        margin-bottom: -25%;
        
    }
    
    #success-message-text {
        font-weight: 300;
        font-size: 3.5vw;
        max-width: fit-content;
        margin-left: -5.5%;
        margin-top: 3%;
    }
}

@media only screen and (max-width: 300px) and (min-width:100px){

    #success-message {
        text-align: start;
        margin-left: 8%;
        margin-top: 2%;
        margin-bottom: -25%;
        
    }
    
    #success-message-text {
        font-weight: 300;
        font-size: 4.5vw;
        max-width: fit-content;
        margin-left: -10%;
        margin-top: 3%;
    }
}