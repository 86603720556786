.footer-ban {
    height: auto;
}

#footer-img {
    width: 8%;
    margin-bottom: 1.8%;
    margin-left: 46.5vw;
    margin-top: 2%;
  
}

@media only screen and (max-width: 1000px) and (min-width: 800px){
    #footer-img {
        width: 9%;
        margin-bottom: 1.5%;
    }
}

@media only screen and (max-width: 800px) and (min-width: 700px){
    #footer-img {
        width: 12%;
        margin-bottom: 1.5%;
        margin-left: 43vw;
    }
}

@media only screen and (max-width: 700px) and (min-width: 600px){
    #footer-img {
        width: 14%;
        margin-bottom: 1.5%;
        margin-left: 40vw;
    }
}

@media only screen and (max-width: 600px) and (min-width: 500px){
    #footer-img {
        width: 16%;
        margin-bottom: 1.5%;
        margin-left: 41vw;
    }
}

@media only screen and (max-width: 500px) and (min-width: 400px){
    #footer-img {
        width: 19%;
        margin-bottom: 1.5%;
        margin-left: 40vw;
    }
}

@media only screen and (max-width: 400px) and (min-width: 300px){
    #footer-img {
        width: 23%;
        margin-bottom: 1.5%;
        margin-left: 40vw;
    }
}

@media only screen and (max-width: 300px) and (min-width: 200px){
    #footer-img {
        width: 25%;
        margin-bottom: 1.5%;
        margin-left: 42vw;
    }
}