#qr-img {
    width: 7vw;
}

.link-ban-flex {
    text-align: center;
}

.link-ban-flex-parent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 90vw;
    margin-left: 8.3vw;
    margin-top: 4vw;
    margin-bottom: 2vw;
}

#link-ban-flex-parent1 {
    display: flex;
    flex-direction: row;
    /* justify-content: flex-end; */
}

#link-ban-flex-parent2 {
    display: flex;
    flex-direction: row;
    /* justify-content: flex-start; */
}

#link-ban-flex-parent3 {
    display: flex;
    flex-direction: row;
    /* justify-content: flex-start; */
}

#qr-text {
    font-size: 1vw;
    color: #243B56;
    font-weight: 400;
}

a {
    text-decoration: none;
}


.link-ban-flex-child {
    margin-right: 5vw;
}
@media screen and (max-width: 1200px) and (min-width:816px){

    #qr-text {
        font-size: 1.4vw;
    }

    #qr-img {
        width: 8vw;
    }
}

@media screen and (max-width: 816px) and (min-width:600px){

    #qr-text {
        font-size: 2vw;
    }

    #qr-img {
        width: 13vw;
    }

    .link-ban-flex-parent {
        margin-top: 8vw;
    }
}

@media screen and (max-width: 600px) and (min-width:400px){

    #qr-text {
        font-size: 2.6vw;
    }

    #qr-img {
        width: 17vw;
    }

    .link-ban-flex-parent {
        margin-top: 9vw;
    }

    #link-ban-flex-parent2 {
        margin-right: 2%;
    }
}

@media screen and (max-width: 400px){

    .link-ban-flex-parent {
        display: flex;
        flex-direction: column; 
        margin-left: 18vw;
        margin-top: 15vw;
    }

    #qr-text {
        font-size: 4vw;
    }

    #qr-img {
        width: 30vw;
    }

    #link-ban-flex-parent2 {
        margin-right: 15%;
    }
    
}
