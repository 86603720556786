.org-ban {
    height: auto;
    margin-top: 8vw;
    text-align: center;
}

#org-heading1 {
    font-size: 2.7vw;
    font-weight: 400;
    margin-left: 0.8%;
}

/* #org-heading2 {
    font-size: 4vw;
    font-weight: 400;
} */

#org-head-text {
    margin-top: -6vw;
}

#org-subheading {
    font-size: 2vw;
    font-weight: 400;
    /* margin-left: 57vw; */
}
/* #org-subhead-text {
    margin-top: 3vw;
    margin-bottom: 4vw;
} */

@media screen and (max-width: 1200px) and (min-width: 1000px){
#org-heading1 {
    font-size: 3.5vw;
}
}

@media screen and (max-width: 1000px) and (min-width: 600px) {
#org-heading1 {
    font-size: 3.93vw;
}
}

@media screen and (max-width: 600px) and (min-width: 450px) {
    #org-heading1 {
        font-size: 4.85vw;
    }
    }

@media screen and (max-width: 450px) and (min-width: 350px) {
    #org-heading1 {
        font-size: 4.85vw;
    }
    }
    
@media screen and (max-width: 350px) and (min-width: 100px) {
    #org-heading1 {
        font-size: 6vw;
    }
    }

@media screen and (max-width: 1430px) and (min-width: 1100px) {

    /* #org-heading1 {
        font-size: 5.4vw;
    } */
    
    /* #org-heading2 {
        font-size: 5.4vw;
    } */

    /* #org-subheading {
        font-size: 3vw;
    } */
}

@media screen and (max-width: 1100px) and (min-width: 900px) {

    /* #org-heading1 {
        font-size: 5.8vw;
    } */
    
    /* #org-heading2 {
        font-size: 5.8vw;
    } */

    /* #org-subheading {
        font-size: 3.3vw;
    } */
}

@media screen and (max-width: 900px) and (min-width: 500px) {

    /* #org-heading1 {
        font-size: 6vw;
    } */
    
    /* #org-heading2 {
        font-size: 6vw;
    } */

    /* #org-subheading {
        font-size: 3.5vw;
    } */
}

@media screen and (max-width: 500px) and (min-width: 100px) {

    /* #org-heading1 {
        font-size: 8vw;
    } */
    
    /* #org-heading2 {
        font-size: 8vw;
    } */

    /* #org-subheading {
        font-size: 5vw;
    } */
}