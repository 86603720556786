.video-ban {
    width: 100%;
    height: 80vh;
    position: relative;
    z-index:0;
    object-fit: cover;
  }
  
@media (max-width: 1125px) {
    .video-ban {
      height: 70vh;
    }
}

@media (max-width: 890px) {
  .video-ban {
    height: 60vh;
  }
}

@media (max-width: 400px) and (max-height: 700px) {
   
  .video-ban {
    height: 70vh;
  }

  #test-img {
    height: 70vh !important;
  }
}

  .video-ban:before {
    content: '';
    position: absolute;
    background: rgba(38, 70, 109, 0.75);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  #background-video {
    display: block;
  }
    
  #test-img {
    height: 60vh;
  }
  
  @media (max-width: 800px) {
  
    #test-img {
      display: block;
    }
  }

  #background-video{
    width: 100%;
    height: 100%;
    object-fit: fill;
    pointer-events: none;
    }


.overlay {
    position:absolute;
    top:33%;
    left:9%;
    z-index:1;
    height: 100%;
    color: rgb(255, 255, 255);
    font-size: larger;
    text-align: center;
}

#heading-1{
    font-size: 4vw;
    width: 0;
    animation: typing 1s steps(23, end) forwards;
    margin-bottom: 0%
}


.text-container {
  max-width: 75vw;
  margin-left: 5.6vw;
  display: inline-block;
  text-align: left;
  
  margin-top: -3%;
  }  


@keyframes typing {
    from { width: 100% }
    to { width: 100% }
  }


#heading-2{
    font-size: 1.4vw;
    font-weight: 300;
    opacity: 0;
    animation: text-visibilty 1s 1s forwards;
    padding-top: 4px;
}

@keyframes text-visibilty {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media screen and (max-width: 1430px) and (min-width:1100px){
  
  #heading-1{
    font-size: 4.6vw;
}
  
#heading-2{
  font-size: 1.6vw;
}

.overlay {
  top:35%;
  left:10%;
}

.text-container {
  margin-left: 4vw;
  } 

  }

  @media screen and (max-width: 1100px) and (min-width:900px){
  
    #heading-1{
      font-size: 5.2vw;
  }
    
  #heading-2{
    font-size: 1.8vw;

  }
  
  .overlay {
    top:35%;
    left:11%;
  }

  .text-container {
    margin-left: 3vw;
      } 
    }

@media screen and (max-width: 900px) and (min-width:700px){
  
    #heading-1{
        font-size: 6.1vw;
    }
      
    #heading-2{
      font-size: 2.3vw;
    }
 
    .overlay {
      top:35%;
      left:12%;
    }
    .text-container {
      margin-left: 1.9vw;
    } 
  }

@media screen and (max-width: 700px) and (min-width:600px){

  #heading-1{
    font-size: 6.6vw;
}
  
#heading-2{
  font-size: 2.5vw;

}

.overlay {
  top:35%;
  left:17.8%;
}

.text-container {
  display: inline-block;
  }  

  .text-container {
    margin-left: -4vw;
    } 

  }

  @media screen and (max-width: 600px) and (min-width:500px){

    #heading-1{
      font-size: 7.6vw;
  }
    
  #heading-2{
    font-size: 2.9vw;
  }
  
  .overlay {
    top:26%;
    left:18.5%;
  }
  
  .text-container {
    margin-left: -8vw;
    display: inline-block;
    }  
    }

@media screen and (max-width: 500px) and (min-width:400px){

#heading-1{
  font-size: 8.3vw;
}
  
#heading-2{
  font-size: 3.4vw;

}

.overlay {
  top:25%;
  left:24.5%;
}

.text-container {
  margin-left: -12vw;
  display: inline-block;
  }  

  }

  @media screen and (max-width: 400px) and (min-width:200px){

    #heading-1{
      font-size: 10vw;
  }
    
  #heading-2{
    font-size: 4vw;
  
  }
  
  .overlay {
    top:26%;
    left:21%;
  }
  
  .text-container {
    max-width: 80vw;
    margin-left: -8vw;
    display: inline-block;
    }  
  
    }