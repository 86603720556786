.feature-ban {
    background-color: #f7f7f7;
    padding-top: 4%;
    padding-bottom: 4%;
}

#accomplish-text {
    margin-left: 31.7vw;
}

#feature-flex-parent{
    margin-top: 7vh;
    margin-left: 27.2vw;
    width: 50vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#feature-flex-parent-2{
    margin-top: 7vh;
    margin-left: 38.5vw;
    width: 30vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


#flex-child-2 {
    margin-left: -2.5%;
}
#flex-child-image {
    height: 4vw;
    margin-left: 15.3%;
}

/* #feature-ban-text{
    max-width: 12vw;
    margin-left: -1.8vw;
    text-align: center;
} */

/* #feature-ban-text-0{
    max-width: 12vw;
    margin-left: -1.8vw;
    text-align: center;
} */

#feature-heading1 {
    font-size: 2.3vw;
    font-weight: 500;
    margin-left: -1.5%;
}

#feature-heading2 {
    font-size: 2.3vw;
    font-weight: 500;
}

#feature-ban-text-0 {
    font-size: 1.2vw;
    font-weight: 600;
    max-width: 12vw;
    margin-left: -1.8vw;
    text-align: center;
}

#feature-ban-text {
    font-size: 0.8vw;
    font-weight: 450;
    max-width: 12vw;
    margin-left: -1.8vw;
    text-align: center;
}


#feature-ban-text-0-2 {
    font-size: 1.2vw;
    font-weight: 600;
    max-width: 12vw;
    margin-left: -1.8vw;
    text-align: center;
}

#feature-ban-text-2 {
    font-size: 0.8vw;
    font-weight: 450;
    max-width: 12vw;
    margin-left: -1.8vw;
    text-align: center;
}


@media screen and (max-width: 1300px) and (min-width:1150px){
    #feature-heading1 {
        font-size: 2.5vw;
        margin-left: -4.6%;
    }
    
    #feature-heading2 {
        font-size: 2.5vw;
    }
    
    #feature-ban-text-0 {
        font-size: 1.2vw;
    }
    
    #feature-ban-text {
        font-size: 0.9vw;
        max-width: 14vw;
    }

    #feature-ban-text-0-2 {
        font-size: 1.2vw;
    }
    
    #feature-ban-text-2 {
        font-size: 0.9vw;
        max-width: 14vw;
    }
   
}

@media screen and (max-width: 1150px) and (min-width:920px){
    #feature-heading1 {
        font-size: 2.9vw;
        margin-left: -8.5%;
    }
    
    #feature-heading2 {
        font-size: 2.9vw;
    }
    
    #feature-ban-text-0 {
        font-size: 1.4vw;
        max-width: 14vw;
    }
    
    #feature-ban-text {
        font-size: 1.05vw;
        max-width: 15vw;
    }

    #feature-ban-text-0-2 {
        font-size: 1.4vw;
        max-width: 14vw;
    }
    
    #feature-ban-text-2 {
        font-size: 1.05vw;
        max-width: 15vw;
    }
   
}

@media screen and (max-width: 920px) and (min-width:800px){

    #accomplish-text {
        margin-left: 25.8vw;
    }

    

    #feature-heading1 {
        font-size: 3.3vw;
        margin-left: -2.6%;
    }
    
    #feature-heading2 {
        font-size: 3.3vw;
    }
    
    #feature-ban-text-0 {
        font-size: 1.6vw;
        max-width: 16vw;
    }
    
    #feature-ban-text {
        font-size: 1.2vw;
        max-width: 15vw;
    }

    #feature-ban-text-0-2 {
        font-size: 1.6vw;
        max-width: 16vw;
    }
    
    #feature-ban-text-2 {
        font-size: 1.2vw;
        max-width: 15vw;
    } 

    #flex-child-image {
        /* height: 4vw; */
        margin-left: 22%;
    }
   
}

@media screen and (max-width: 800px) and (min-width:700px){

    #accomplish-text {
        margin-left: 21.8vw;
    }

    #feature-flex-parent {
        width: 64vw;
        margin-left: 20%;
    }

    #feature-flex-parent-2 {
        width: 44vw;
        margin-left: 33%
    }

    #feature-heading1 {
        font-size: 4vw;
        margin-left: -6%;
    }
    
    #feature-heading2 {
        font-size: 4vw;
    }
    
    #feature-ban-text-0 {
        font-size: 1.8vw;
        max-width: 20vw;
    }
    
    #feature-ban-text {
        font-size: 1.4vw;
        max-width: 20vw;
        margin-left: -7%;
    }

    #feature-ban-text-0-2 {
        font-size: 1.8vw;
        max-width: 20vw;
    }
    
    #feature-ban-text-2 {
        font-size: 1.4vw;
        max-width: 21vw;
        margin-left: -10%;
    }

    #flex-child-image {
        /* height: 4vw; */
        margin-left: 27%;
    }
   
}

@media screen and (max-width: 700px) and (min-width:530px){

    #accomplish-text {
        margin-left: 14vw;
        max-width: 70vw;
    }

    #flex-child-image {
        height: 8vw;
        margin-left: 15.3%;
    }
    #feature-flex-parent{
        margin-left: 40vw;
        flex-direction: column;
        margin-left: 36%;
    }
    
    #feature-flex-parent-2{
        margin-top: 0vh;
        margin-left: 42vw;
        flex-direction: column;
    }
   
    #flex-child-2 {
        margin-left: -9.5%;
    }

    #feature-ban-text{
        max-width: 25vw;
        margin-left: 0.5vw;
        text-align: center;
        font-size: 1.8vw;
        font-weight: 400;
    }

    #feature-ban-text-2{
        max-width: 26vw;
        margin-left: -3vw;
        text-align: center;
        font-size: 1.8vw;
        font-weight: 400;
    }

    #feature-ban-text-0{
        max-width: 25vw;
        margin-left:0vw;
        text-align: center;
    }

    #feature-heading1 {
        font-size: 5vw;
        font-weight: 400;
    }

    #feature-heading2 {
        font-size: 5vw;
        font-weight: 450;
    }

    #feature-ban-text-0 {
        font-size: 2.7vw;
        font-weight: 600;
    }

    #feature-ban-text-0-2 {
        max-width: 23vw;
        font-size: 2.7vw;
        font-weight: 600;
        margin-left: -1vw;
        text-align: center;
    }
    
}

@media screen and (max-width: 530px) and (min-width:400px){
    #accomplish-text {
        margin-left: 14vw;
        max-width: 70vw;
    }

    #flex-child-image {
        height: 8vw;
        margin-left: 17.3%;
    }
    #feature-flex-parent{
        margin-left: 38vw;
        flex-direction: column;
    }
    
    #feature-flex-parent-2{
        margin-top: 0vh;
        margin-left: 40.8vw;
        flex-direction: column;
    }
   

    #feature-ban-text{
        max-width: 35vw;
        margin-left: -3.7vw;
        text-align: center;
        font-size: 2.4vw;
        font-weight: 400;
    }

    #feature-ban-text-2{
        max-width: 35vw;
        margin-left: -7vw;
        text-align: center;
        font-size: 2.4vw;
        font-weight: 400;
    }

    #feature-ban-text-0{
        max-width: 25vw;
        margin-left:0vw;
        text-align: center;
    }

    #feature-heading1 {
        font-size: 5vw;
        font-weight: 400;
    }

    #feature-heading2 {
        font-size: 5vw;
        font-weight: 450;
    }

    #feature-ban-text-0 {
        font-size: 3vw;
        font-weight: 600;
    }

    #feature-ban-text-0-2 {
        max-width: 22vw;
        font-size: 3vw;
        font-weight: 600;
        margin-left: -1vw;
        text-align: center;
    }
    
}

@media screen and (max-width: 700px) {
    #flex-child {
        margin-bottom: 17%;
    }
}

@media screen and (max-width: 400px) and (min-width:200px){
    #accomplish-text {
        margin-left: 14vw;
        max-width: 80vw;
    }

    #flex-child-image {
        height: 8vw;
        margin-left: 13.8%;
    }
    #feature-flex-parent{
        margin-left: 38vw;
        flex-direction: column;
    }
    
    #feature-flex-parent-2{
        margin-top: 0vh;
        margin-left: 41vw;
        flex-direction: column;
    }
   

    #feature-ban-text{
        max-width: 42vw;
        margin-left: -10vw;
        text-align: center;
        font-size: 3vw;
        font-weight: 400;
    }

    #feature-ban-text-2{
        max-width: 45vw;
        margin-left: -14.5vw;
        text-align: center;
        font-size: 3vw;
        font-weight: 400;
    }

    #feature-heading1 {
        font-size: 9vw;
        font-weight: 400;
    }

    #feature-heading2 {
        font-size: 9vw;
        font-weight: 450;
    }

    #feature-ban-text-0 {
        font-size: 4.5vw;
        font-weight: 600;
        max-width: 45vw;
        margin-left:-11vw;
        text-align: center;
    }

    #feature-ban-text-0-2 {
        max-width: 35vw;
        font-size: 4.5vw;
        font-weight: 600;
        margin-left: -10vw;
        text-align: center;
    }
    
}