.ctp-video-ban {
    background-color: #f7f7f7;
    height: auto;
}


#ctp-video-title {
    padding-top: 5vw;
    margin-left: 15vw;
}

#ctp-video-title-1 {
    font-size: 4vw;
}

#ctp-video-title-2 {
    font-size: 4vw;
}


#ctp-video-text {
    margin-top: 3%;
    font-size: 1.4vw;
    font-weight: 400;
    max-width: 70vw;
    margin-bottom: 5vw;
}

#video-icon-title {
    font-size: 1vw;
}

#yt-vid {
    width: 30vw !important;
    height: 32vh !important;
} 

#ctp-more-video-parent-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: -5%;
    margin-top: -3%;
}

#ctp-more-video-child-icon {
    width: 18%;
    height: 18%;
    margin-bottom: 2%;
    margin: 1%;
}

#ctp-more-video-child-icon {
    text-align: center;
}

@media only screen and (max-width: 1430px) and (min-width: 1100px){
    #ctp-video-title-1 {
        font-size: 4.6vw;
    }
    
    #ctp-video-title-2 {
        font-size: 4.6vw;
    } 

    #ctp-video-text {
        font-size: 1.6vw;
    }

    #video-icon-title {
        font-size: 1.1vw;
        margin-top: 0%;
    }

    #ctp-ppt-link {
        font-size: 2vw !important;
    }

    .ctp-yt-video-parent {
        margin-left: 0 !important;
    }

    #yt-vid {
        width: 32vw !important;
        height: 29vh !important;
    } 
}

@media only screen and (max-width: 1100px) and (min-width: 900px){
    #ctp-video-title-1 {
        font-size: 5.2vw;
    }
    
    #ctp-video-title-2 {
        font-size: 5.2vw;
    } 

    #ctp-video-text {
        font-size: 1.8vw;
    }

    #video-icon-title {
        font-size: 1.3vw;
        margin-top: -7%;
    }

    #ctp-ppt-link {
        font-size: 2.2vw !important;
    }

    .ctp-yt-video-parent {
        margin-left: 0 !important;
    }

    #yt-vid {
        width: 40vw !important;
        height: 27vh !important;
    } 
}

@media only screen and (max-width: 900px) and (min-width: 700px){
    #ctp-video-title-1 {
        font-size: 6.1vw;
    }
    
    #ctp-video-title-2 {
        font-size: 6.1vw;
    } 

    #ctp-video-text {
        font-size: 2.3vw;
    }

    #video-icon-title {
        font-size: 1.6vw;
    }

    .ctp-yt-video-parent {
        flex-direction: column !important;
        justify-content: flex-start !important;
        margin-left: 25% !important;
    }

    #yt-vid {
        width: 50vw !important;
        height: 30vh !important;
    } 

    #ctp-ppt-link {
        margin-top: 5% !important;
        width: 40% !important;
        font-size: 2.5vw !important;
        margin-left: 13.5% !important;
    }

    #ctp-more-video-parent-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: -5%;
        margin-top: -3%;
    }

    #ctp-more-video-child-icon {
        width: 30%;
        height: 30%;
        margin-left: 37%;
    }

    #video-icon-title {
        margin-top: -1%;
    }
   
}

@media only screen and (max-width: 700px) and (min-width: 600px){
    #ctp-video-title-1 {
        font-size: 6.6vw;
    }
    
    #ctp-video-title-2 {
        font-size: 6.6vw;
    } 

    #ctp-video-text {
        font-size: 2.5vw;
    }
    #video-icon-title {
        font-size: 1.9vw;
        margin-top: 2%;
    }

    .ctp-yt-video-parent {
        flex-direction: column !important;
        justify-content: flex-start !important;
        margin-left: 22% !important;
    }

    #yt-vid {
        width: 55vw !important;
        height: 30vh !important;
    } 

    #ctp-ppt-link {
        margin-top: 5% !important;
        width: 50% !important;
        font-size: 3vw !important;
        margin-left: 13.5% !important;
    }

    #ctp-more-video-parent-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: -5%;
        margin-top: -3%;
    }

    #ctp-more-video-child-icon {
        width: 35%;
        height: 35%;
        margin-left: 32%;
        margin-bottom: -1%;
    }

    /* #video-icon-title {
        margin-top: -3%;
    } */
}

@media only screen and (max-width: 600px) and (min-width: 500px){
    #ctp-video-title-1 {
        font-size: 7.6vw;
    }
    
    #ctp-video-title-2 {
        font-size: 7.6vw;
    } 

    #ctp-video-text {
        font-size: 2.9vw;
    }

    .ctp-yt-video-parent {
        flex-direction: column !important;
        justify-content: flex-start !important;
        margin-left: 22% !important;
    }

    #yt-vid {
        width: 55vw !important;
        height: 30vh !important;
    } 

    #ctp-ppt-link {
        margin-top: 5% !important;
        width: 50% !important;
        font-size: 3.5vw !important;
        margin-left: 11% !important;
    }

    #ctp-more-video-parent-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: -5%;
        margin-top: -3%;
    }

    #ctp-more-video-child-icon {
        width: 40%;
        height: 40%;
        margin-left: 32%;
        margin-bottom: -2%;
    }

    #video-icon-title {
        font-size: 2.2vw;
        margin-top: 2%;
    }
}

@media only screen and (max-width: 500px) and (min-width: 400px){
    #ctp-video-title-1 {
        font-size: 8.3vw;
    }
    
    #ctp-video-title-2 {
        font-size: 8.3vw;
    }

    #ctp-video-text {
        font-size: 3.4vw;
    }

    .ctp-yt-video-parent {
        flex-direction: column !important;
        justify-content: flex-start !important;
        margin-left: 15% !important;
    }

    #yt-vid {
        width: 70vw !important;
        height: 25vh !important;
    } 

    #ctp-ppt-link {
        margin-top: 5% !important;
        width: 60% !important;
        font-size: 4vw !important;
        margin-left: 0% !important;
    }

    #ctp-more-video-parent-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: -5%;
    }

    #ctp-more-video-child-icon {
        width: 50%;
        height: 50%;
        margin-left: 28%;
        /* margin-top: -5%; */
    }

    #video-icon-title {
        font-size: 2.6vw;
        margin-top: 2%;
    }
}

@media only screen and (max-width: 400px) and (min-width: 100px){
    #ctp-video-title-1 {
        font-size: 10vw;
    }
    
    #ctp-video-title-2 {
        font-size: 10vw;
    }

    #ctp-video-text {
        font-size: 3.9vw;
    }

    .ctp-yt-video-parent {
        flex-direction: column !important;
        justify-content: flex-start !important;
        margin-left: 15% !important;
    }

    #yt-vid {
        width: 70vw !important;
        height: 25vh !important;
    } 

    #ctp-ppt-link {
        margin-top: 5% !important;
        width: 70% !important;
        font-size: 5vw !important;
        margin-left: 0% !important;
    }

    #ctp-more-video-parent-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: -5%;
    }

    #ctp-more-video-child-icon {
        width: 55%;
        height: 55%;
        margin-left: 25%;
        margin-top: -2%;
    }

    #video-icon-title {
        font-size: 3vw;
        margin-top: -2%;
    }
}

.ctp-yt-video-parent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: -10%;
    padding-bottom: 2%;
    margin-top: -1%;
}

#ctp-ppt-link {
    margin-top: 45%;
    width: 120%;
    font-size: 1.6vw;
    margin-left: 20%;
}

#yt-vid {
    width: 32vw;
    height: 35vh;
} 

