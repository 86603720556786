.partner-ban {
    height: fit-content;
    background-color: #f7f7f7;
    padding-top: 0.09vh;
    padding-bottom: 2vh;
    margin-top: 4%;
}

#partners-text {
    margin-top: 2.5%;
    margin-left: 42vw;
}

#parent-flex-partner {
    text-align: center;
    margin-top: 3%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2%;

}

#smile-child-image {
    margin-top: -7%;
    width: 6vw;
    margin-left: 3vw;
}

#mila-child-image {
    width: 9vw;
    margin-right: 3vw;
}

#partners-text-1 {
    font-size: 2.7vw; 
    font-weight: 400;
} 

/* @media screen and (max-width: 1300px) and (min-width:1200px){
    .partner-ban {
        height: 23vh; 
    }
} */

@media screen and (max-width: 1200px) and (min-width:1000px){

    /* #parent-flex-partner {
        width: 18vw;
    } */

    #smile-child-image {
        width: 7vw;
    }
    
    #mila-child-image {
        width: 10.5vw;
    }

    #partners-text-1 {
        font-size: 3.5vw; 
    } 

    #partners-text {
        margin-left: 40.2vw;
    }
}

@media screen and (max-width: 1000px) and (min-width:600px){

    /* #parent-flex-partner {
        width: 21vw;
    } */

    #partners-text {
        margin-left: 39vw;
    }

    #smile-child-image {
        width: 8vw;
    }
    
    #mila-child-image {
        width: 11.5vw;
    }

    #partners-text-1 {
        font-size: 3.93vw; 
    } 
}

@media screen and (max-width: 600px) and (min-width:450px){

    /* #parent-flex-partner {
        width: 26vw;
    } */

    #smile-child-image {
        width: 9vw;
        /* margin-top: -7%; */
    }
    
    #mila-child-image {
        width: 13vw;
    }

    #partners-text-1 {
        font-size: 4.85vw; 
    } 

    #partners-text {
        margin-left: 36.5vw;
    }
    
    /* #parent-flex-partner {
        margin-left: 38.5%;
    } */
}

@media screen and (max-width: 450px) and (min-width:350px){

    /* #parent-flex-partner {
        width: 26vw;
    } */

    #smile-child-image {
        width: 17vw;
        margin-top: 8%;
    }
    
    #mila-child-image {
        margin-bottom: -6vw;
        width: 23vw;
        margin-left: 5vw;
    }

    #partners-text-1 {
        font-size: 4.85vw; 
        font-weight: 400;
    } 

    #partners-text {
        margin-left: 37.5vw;
    }
    
    #parent-flex-partner {
        /* margin-left: 47%; */
        flex-direction: column;
    }
    
}

@media screen and (max-width: 350px) and (min-width:100px){

    #smile-child-image {
        width: 16vw;
        margin-top: 8%;
    }
    
    #mila-child-image {
        margin-bottom: -6vw;
        width: 23vw;
        margin-left: 5vw;
    }

    #partners-text-1 {
        font-size: 4.85vw; 
        font-weight: 400;
    } 

    #partners-text {
        margin-left: 38vw;
    }
    
    #parent-flex-partner {
        /* margin-left: 47%; */
        flex-direction: column;
    }


    #partners-text-1 {
        font-size: 6vw; 
        font-weight: 400;
    } 

    #partners-text {
        margin-left: 35vw;
    }
    
}

/* @media screen and (max-height: 900px) {
    .partner-ban {
        height: fit-content;
    }

} */
