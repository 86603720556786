
#parent-grid {
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -4vw;
}

#option-btn {
    text-transform: none;
    font-size: 1.3vw;
    text-align: start;
    line-height: 30px;
    justify-content: flex-start;
 }


#btn-grid {
    margin-left: 11.5vw;
    
 }

#btn-grid-parent {
    margin-top: 30%;
}
 
#option-text-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 12%;
    margin-left: -8vw;
}


#option-line {
    width: 0.1vw;
    height: 22vw;
    background-color: black;
    margin-right: -4vw;
}

#child-icon {
    width: 4vw;
}

#option-text-flex-child {
    text-align: center;
    margin-top: 3%;
    max-width: 15vw;
    margin-right: -2vw;
}


#option-text-head {
    font-size: 1.3vw;
}

#option-text {
    font-weight: 400;
    font-size: 0.9vw;
}

#option-title {
    padding-top: 6vw;
    margin-left: 16vw;
}

#option-title-1 {
    font-size: 4vw;
}

#option-title-2 {
    font-size: 4vw;
}

@media screen and (max-width: 1430px) and (min-width: 1200px) {
    #option-title-1 {
        font-size: 4.6vw;
    }
    
    #option-title-2 {
        font-size: 4.6vw;
    }

    #option-text {
        font-size: 1vw;
    }

    #option-text-head {
        font-size: 1.6vw;
    }

    #option-btn {
        font-size: 1.7vw; 
    }
    
}

@media screen and (max-width: 1200px) and (min-width: 1000px) {

    #child-icon {
        width: 4.4vw;
    }

    #option-title-1 {
        font-size: 5.2vw;
    }
    
    #option-title-2 {
        font-size: 5.2vw;
    }

    #option-text {
        font-size: 1.3vw;
    }

    #option-text-head {
        font-size: 1.9vw;
    }

    #option-btn {
        font-size: 1.8vw; 
    }
    #option-line {
        height: 28vw;
    }
    
    
}


@media screen and (max-width: 1000px) and (min-width: 860px) {

    #child-icon {
        width: 5vw;
    }

    #option-title-1 {
        font-size: 5.7vw;
    }
    
    #option-title-2 {
        font-size: 5.7vw;
    }

    #option-text {
        font-size: 1.5vw;
    }

    #option-text-head {
        font-size: 2.2vw;
    }

    #option-btn {
        font-size: 2vw; 
    }

    #option-line {
        height: 35vw;
    }
    
}

@media screen and (max-width: 860px) and (min-width: 800px) {

    #child-icon {
        width: 5vw;
    }

    #option-title-1 {
        font-size: 6.1vw;
    }
    
    #option-title-2 {
        font-size: 6.1vw;
    }

    #option-text {
        font-size: 1.5vw;
    }

    #option-text-head {
        font-size: 2.2vw;
    }

    #option-btn {
        font-size: 2vw; 
    }

    #option-line {
        height: 36vw;
    }
    
}