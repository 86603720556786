/* body {
    overflow-x: hidden;
    font-family: "Open Sans", sans-serif;

} */


.MuiButton-root {
    font-family: "Inter", sans-serif !important;
 }


#root {
    overflow: hidden;
    width: 100%;
}

html, body {
    scroll-behavior: auto;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    scroll-padding-top: 80px;
    scroll-margin-top: 80px;
  }