.chart-container {
  width: 90%;
  margin-left: 5%;
}

.chart-container .chart {
  position: relative;
}

@media (max-width: 768px) {

  .chart-container .chart {
    display: none;
  }

}

.chart-container .chart img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  opacity: 0;
}

.chart-container .chart img.visible {
  opacity: 100%;
}

.chart-container .chart .hover-area {
  position: absolute;
  height: 82%;
  top: 18%;
  z-index: 999;
}

.chart-container .chart .hover-area.hover-area-1 {
  left: 0.5%;
  width: 19.5%;
}

.chart-container .chart .hover-area.hover-area-2 {
  left: 20%;
  width: 11%;
}

.chart-container .chart .hover-area.hover-area-3 {
  left: 31%;
  width: 26%;
}

.chart-container .chart .hover-area.hover-area-4 {
  left: 57%;
  width: 20%;
}

.chart-container .chart .hover-area.hover-area-5 {
  left: 77%;
  width: 23%;
}

.chart-container .chart-mobile {
  display: none;
  text-align: center;
}

.chart-container .chart-mobile > img:first-child {
  margin-bottom: 40px;

}
@media (max-width: 768px) {
  .chart-container .chart-mobile {
    display: block;
  }
  .chart-container .chart-mobile .section {
    width: 100%;
  }
  .chart-container .chart-mobile .section > div:first-child {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 28px;
    width: 100%;
    padding: 28px;
    cursor: pointer;
  }
  .chart-container .chart-mobile .section > div:first-child span {
    margin-right: 24px;
  }
  .chart-container .chart-mobile .section > div:last-child {
    padding: 31px;
    height: 0;
    display: none;
    overflow: hidden;
  }
  .chart-container .chart-mobile .section > div:last-child.expanded {
    display: block;
    height: unset;
  }
  .chart-container .chart-mobile .section > div:last-child img {
    max-width: 100%;
    height: auto;
  }
  .chart-container .chart-mobile .section > div:last-child h5 {
    font-weight: 500;
    font-size: 32px;
    text-align: start;
    margin: 15px 0;
  }
  .chart-container .chart-mobile .section > div:last-child p {
    font-weight: 400;
    font-size: 18px;
    text-align: start;
    margin: 0;
  }
}