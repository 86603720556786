
.res-found-ban {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 2.6%;
}

#res-found-ban-title {
    max-width: 70vw;
    margin-top: 8%;
}

#res-found-ban-title-1, #res-found-ban-title-2 {
    font-size: 4vw;
}

#stack-found-image {
    width: 27vw;
    margin-top: 30%;
    margin-left: -27%;
}

#res-found-ban-text {
    font-size: 1.4vw;
    font-weight: 400;
}

#found-stack-flex {
    display: flex;
    flex-direction: row;
}

#res-found-ban-subtitle {
    margin-bottom: -1%;
    font-size: 2.6vw;
    margin-left: -52%;
}

#found-stack-text {
    padding-left: 0%;
    margin-top: 2%;
    max-width: 37vw;
    height: auto;
    
}

#res-found-ban-subtitle-1 {
    font-size: 1.6vw;
    margin-bottom: -3%;
    
}

#res-found-ban-subtext {
    font-size: 1.1vw;
    font-weight: 300;
    margin-top: 4%;
}

@media screen and (max-width: 1430px) and (min-width:1100px){

    #stack-found-image {
        width: 30vw;
    }

    #res-found-ban-title-1, #res-found-ban-title-2 {
        font-size: 4.6vw;
    }

    #res-found-ban-text {
        font-size: 1.6vw;
    }

    #res-found-ban-subtitle {
        font-size: 2.8vw;
        margin-left: -49.8%;
    }

    #res-found-ban-subtitle-1 {
        font-size: 2vw;
    }

    #res-found-ban-subtext {
        font-size: 1.3vw;
    }

    #found-stack-text {
        padding-left: 3%;
        margin-top: 0%;
        max-width: 43vw;
    }

    /* ----------------------------------------------------------  */
    
     #res-found-subtitle-1 {
        font-size: 1.7vw;
        font-weight: 600;
        max-width: 13vw;
     }
    
     #res-found-subtext {
        font-size: 1.3vw;
        font-weight: 300;
     }
    
     #res-found-child-1 {
        max-width: 25vw;
        margin-top: 14.5%;
        margin-right: 8%;
     }
    
     #res-found-child-3 {
        max-width: 25vw;
        margin-left: -8.2%;
     }
 }

 @media screen and (max-width: 1100px) and (min-width:900px){

    #stack-found-image {
        width: 33vw;
        /* margin-top: 38%;
        margin-left: 2vw; */
    }

    #res-found-ban-title-1, #res-found-ban-title-2 {
        font-size: 5.2vw;
    }

    #res-found-ban-text {
        font-size: 1.8vw;

    }

    #res-found-ban-subtitle {
        font-size: 3vw;
        margin-left: -48.1%;
        margin-bottom: -2%;
    }

    #res-found-ban-subtitle-1 {
        font-size: 2vw;
    }

    #res-found-ban-subtext {
        font-size: 1.3vw;
    }

    #found-stack-text {
        padding-left: 3%;
        margin-top: 0%;
        max-width: 43vw;
    }

    /* ------------------------------ */

    #res-found-parent-2 {
        margin-left: 2%;
    }

    #res-found-subtitle-1 {
        font-size: 2vw;
        font-weight: 600;
        max-width: 15vw;
     }
    
     #res-found-subtext {
        font-size: 1.5vw;
        font-weight: 300;
     }
    
     #res-found-child-1 {
        max-width: 25vw;
        margin-top: 14.5%;
        margin-right: 9%;
     }
    
     #res-found-child-3 {
        max-width: 25vw;
        margin-left: -10%;
     }
 }


 @media screen and (max-width: 900px) and (min-width:700px){

    #res-found-ban-title {
        max-width: 70vw;
        margin-left: 3%;
    }

    #stack-found-image {
        width: 39vw;
        /* margin-top: 40%; */
        /* margin-left: 2vw; */
    }

    #res-found-ban-title-1, #res-found-ban-title-2 {
        font-size: 6.1vw;
    }

    #res-found-ban-text {
        font-size: 2.3vw;
    }

    #res-found-ban-subtitle {
        font-size: 3.5vw;
        margin-left: -39.6%;
        margin-bottom: -2%;
    }

    #res-found-ban-subtitle-1 {
        font-size: 2.6vw;
    }

    #res-found-ban-subtext {
        font-size: 1.8vw;
    }

    #found-stack-text {
        padding-left: 2%;
        margin-top: 0%;
        max-width: 50vw;
    }

    /*  ------------------  */

    #res-found-parent-2 {
        margin-left: 0%;
    }

    #res-found-subtitle-1 {
        font-size: 2.3vw;
        font-weight: 600;
        max-width: 16vw;
     }
    
     #res-found-subtext {
        font-size: 1.7vw;
        font-weight: 300;
     }
    
     #res-found-child-1 {
        max-width: 25vw;
        margin-top: 14.5%;
        margin-right: 8.2%;
     }
    
     #res-found-child-3 {
        max-width: 25vw;
        margin-left: -11%;
     }
 }

 /* ---------------------------------------------------------------------------------------------------------- */
  /* ---------------------------------------------------------------------------------------------------------- */

   /* ---------------------------------------------------------------------------------------------------------- */

    /* ---------------------------------------------------------------------------------------------------------- */


 @media screen and (max-width: 700px){
    #found-stack-flex {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    #res-found-ban-subtext {
        margin-top: 1.6%;
    }

    #res-found-child-3 , #res-found-child-1 {
        display: none;
    }

    #res-found-ban-subtitle {
        font-size: 3.5vw;
        margin-left: -40%;
        margin-bottom: -2%;
    }
 }


 @media screen and (max-width: 700px) and (min-width: 500px) {

    #res-found-ban-title {
        max-width: 80vw;
    }

    #stack-found-image {
        width: 55vw;
        margin-top: 4%;
    }

    #res-found-ban-title-1, #res-found-ban-title-2 {
        font-size: 6.6vw;
    }

    #res-found-ban-text {
        font-size: 2.6vw;
    }

    #res-found-ban-subtitle {
        font-size: 5.2vw;
        margin-bottom: 5%;
        margin-left: -40%;
    }

    #res-found-ban-subtitle-1 {
        font-size: 3.2vw;
        margin-bottom: 1%;
    }

    #res-found-ban-subtext {
        font-size: 2.3vw;
        margin-bottom: 7%;
    }

    #found-stack-text {
        margin-top: 3%;
        max-width: 80vw;
        margin-left: 1.6%;
        text-align: start;
    }

 }

 @media screen and (max-width: 500px) and (min-width: 350px) {

    #res-found-ban-title {
        max-width: 80vw;
    }

    #stack-found-image {
        width: 60vw;
        margin-top: 4%;
    }

    #res-found-ban-title-1, #res-found-ban-title-2 {
        font-size: 8.3vw;
    }

    #res-found-ban-text {
        font-size: 3.45vw;
    }

    #res-found-ban-subtitle {
        font-size: 6vw;
        margin-bottom: 3%;
        margin-left: -33.8%;
    }

    #res-found-ban-subtitle-1 {
        font-size: 3.9vw;
        margin-bottom: 1%;
    }

    #res-found-ban-subtext {
        font-size: 2.9vw;
        margin-bottom: 7%;
    }

    #found-stack-text {
        /* padding-left: 8%; */
        margin-top: 3%;
        max-width: 80vw;
        text-align: start;
    }

 }
 

 @media screen and (max-width: 350px) and (min-width: 100px) {

    #res-found-ban-title {
        max-width: 80vw;
    }

    #stack-found-image {
        width: 80vw;
        margin-top: 4%;
    }

    #res-found-ban-title-1, #res-found-ban-title-2 {
        font-size: 10vw;
    }

    #res-found-ban-text {
        font-size: 4vw;
    }

    #res-found-ban-subtitle {
        font-size: 7vw;
        margin-bottom: 2%;
        margin-left: -26%;
    }

    #res-found-ban-subtitle-1 {
        font-size: 4.5vw;
        margin-bottom: -3%;
    }

    #res-found-ban-subtext {
        font-size: 3.4vw;
        margin-bottom: 7%;
    }

    #found-stack-text {
        /* padding-left: 8%; */
        margin-top: 3%;
        max-width: 80vw;
        text-align: start;
    }

    #res-found-ban-subtext {
        margin-top: 4%;
    }

 }



 /*  */


 #res-found-parent-2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
 }


 #res-found-child-2 {
    z-index: -1;
 }

 @media screen and (min-width: 1430px) {

 #res-found-subtitle-1 {
    font-size: 1.5vw;
    font-weight: 600;
    max-width: 13vw;
 }

 #res-found-subtext {
    font-size: 1.1vw;
    font-weight: 300;
 }

 #res-found-child-1 {
    max-width: 25vw;
    margin-top: 14.5%;
    margin-right: 6.5%;
 }

 #res-found-child-3 {
    max-width: 25vw;
    margin-left: -7.5%;
 }

}

@media screen and (min-width: 700px) {

    #found-stack-flex {
        display: none;
    }
}